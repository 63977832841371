<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Карточка задачи"
    >
      <v-row>
        <v-col cols="12" lg="7" class="d-flex flex-row">
          <v-skeleton-loader
            v-if="(status_code==='draft' && $auth.user().id===fields.customer_id) ||
               ($auth.check(['braude', 'root']) && ['done', 'closed', 'canceled'].indexOf(fields.status) === -1) ||
               Boolean($store.state.loading)"
            :loading="Boolean($store.state.loading)"
            type="button"
            class="mr-2"
          >
            <v-btn color="primary" outlined small :to="`/tasks/${id}/edit`"><v-icon left>mdi-pencil</v-icon>Изменить</v-btn>
          </v-skeleton-loader>
          <v-skeleton-loader
            v-if="[fields.customer.id, fields.executor.id].indexOf($auth.user().id) !== -1 || Boolean($store.state.loading)"
            :loading="Boolean($store.state.loading)"
            type="button"
            class="mr-2"
          >
            <v-btn
              color="primary"
              outlined small @click="copyTask"
            >Копировать</v-btn>
          </v-skeleton-loader>
          <v-skeleton-loader
            v-if="[fields.customer.id, fields.executor.id].indexOf($auth.user().id) !== -1 || Boolean($store.state.loading)"
            :loading="Boolean($store.state.loading)"
            type="button"
          >
            <v-btn
              color="primary"
              outlined small @click="createSubTask"
            >Делегировать</v-btn>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="6" lg="3">
          <div v-for="(value, name, idx) in fields.appraisals" :key="idx" class="d-flex justify-start">
            <span class="text-body-2 pt-1" style="width: 40%">{{ name|mapRating }}</span>
            <v-rating :value="value" readonly dense></v-rating>
          </div>
        </v-col>
        <v-col cols="6" lg="2">
          <div><span class="subtitle-2">Статус: </span><span class="body-2 text-uppercase">{{ status.status || '' }}</span></div>
          <div><span class="subtitle-2">На ком: </span><span class="body-2">{{ status.whom || '' }}</span></div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="8">
          <v-skeleton-loader :loading="loading" type="chip, list-item@14" class="mx-auto">
            <v-list class="view-list v13">
              <v-list-item>
                <v-list-item-content>Задача №:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.code_task }}
                    <span class="ml-4">
                      <v-chip
                        v-for="(item, idx) in fields.tags"
                        :key="idx"
                        small
                        label
                        class="mr-1"
                      >
                        {{ item.name }}
                      </v-chip>
                    </span>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Наименование задачи:</v-list-item-content>
                <v-list-item-content>{{ fields.name }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Категория задачи:</v-list-item-content>
                <v-list-item-content>{{ fields.category }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Заказчик задачи:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.customer.full_name }}
                    <v-btn
                      v-if="$auth.check(['admin'])"
                      icon color="red darken-1" class="ml-2"
                      v-tooltip.bottom="`Смена заказчика`" @click="dialogChangeCustomer"
                    >
                      <v-icon>mdi-account-switch</v-icon>
                    </v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Исполнитель задачи:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.executor.full_name }}
                    <v-btn
                      v-if="$auth.user().id===fields.customer_id || $auth.check(['admin'])"
                      icon color="red darken-1" class="ml-2"
                      v-tooltip.bottom="`Смена исполнителя`" @click="dialogChangeExecutor">
                      <v-icon>mdi-account-switch</v-icon>
                    </v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Срок: {{ $moment(fields.deadline_dt, "YYYY-MM-DD").from($moment(fields.start_dt, "YYYY-MM-DD").subtract(1, 'days'), true) }}</v-list-item-content>
                <v-list-item-content>Начать задачу: {{ $moment(fields.start_dt).format("L") }}, выполнить до: {{ $moment(fields.deadline_dt).format("L") }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Приоритет:</v-list-item-content>
                <v-list-item-content>{{ fields.priority_text }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Трудозатраты:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.labor_costs }} час.
                    <v-btn
                      icon color="red darken-1" class="ml-2"
                      v-if="['draft', 'new'].indexOf(status_code) > -1"
                      @click="dialogLaborCosts = true"
                    >
                      <v-icon>mdi-alarm-check</v-icon>
                    </v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Цель:</v-list-item-content>
                <v-list-item-content>{{ fields.goal }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-start">
                    Результат:
                  </v-card>
                </v-list-item-content>
                <v-list-item-content>
                  <span
                    v-for="(item, idx) in fields.result"
                    :key="idx"
                  ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-start">
                    Нельзя допустить:
                  </v-card>
                </v-list-item-content>
                <v-list-item-content>
                  <span
                    v-for="(item, idx) in fields.not_allowed"
                    :key="idx"
                  ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ item.text }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="fields.project && fields.project.id !== undefined">
                <v-list-item-content>Проект:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.project.name }}
                    <v-btn color="primary" outlined small class="ml-4" :to="`/projects/${fields.project.id}/view`">Проект</v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="fields.project && fields.project.id !== undefined && fields.project_task">
                <v-list-item-content>Задача проекта:</v-list-item-content>
                <v-list-item-content>
                  <v-card flat class="d-flex justify-start align-center">
                    {{ fields.project_task.name }}
                    <v-btn color="primary" outlined small class="ml-4" :to="`/projects/${fields.project.id}/plan`">План</v-btn>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Примечание:</v-list-item-content>
                <v-list-item-content>{{ fields.description }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-skeleton-loader>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card elevation="1">
            <v-skeleton-loader :loading="loading" type="card-heading, divider, list-item-three-line, divider, actions" class="mx-auto">
              <v-card-title>Наблюдатели</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="mb-5">
                  <v-chip
                    v-for="(row, idx) in fields.watchers"
                    :key="idx"
                    class="mr-1 mb-2"
                    small
                    :close="$auth.user().id === row.id"
                    @click:close="watcherRemove(row.id)"
                  >
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    <span :class="{'text-decoration-line-through': row.active==2}">{{ row.short_name }}</span>
                  </v-chip>
                </div>
                <v-combo-multisearch
                  v-model="watchers"
                  :items="items_users"
                  item-value="id"
                  item-text="full_name"
                  item-desc="position"
                  multiple
                  small-chips
                  deletable-chips
                  label="Наблюдатели"
                />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="primary" class="ma-2" small @click="watcherAdd">Добавить</v-btn>
              </v-card-actions>
            </v-skeleton-loader>
          </v-card>
          <v-sub-task
            v-if="subTaskView"
            :id="id"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-attachment :is-closed="fields.is_closed" type="task" :id="id" />
      <v-divider class="mt-5 mb-3"></v-divider>
      <v-row>
        <v-btn color="primary" class="ma-2" small @click="openCommentDialog"><v-icon left>mdi-comment-text-outline</v-icon>Комментарий</v-btn>
        <v-spacer></v-spacer>
        <workflow-button
          :type="`task`"
          :object_id="id"
          :date_obj="{ start_dt: fields.start_dt, deadline_dt: fields.deadline_dt }"
          @changeFields="changeFields"
        />
      </v-row>
    </base-card>
    <v-view-tabs
      :type="`task`"
      :id="id"
      @onAddComment="addComment"
      view-comments
      view-history
    />
    <change-executor-form
      :executor-id="fields.executor.id"
      :id="id"
      @changeExecutor="changeExecutor"
    />
    <change-customer-form
      :customer-id="fields.customer.id"
      :id="id"
      @changeCustomer="changeCustomer"
    />
    <v-dialog v-model="dialogLaborCosts" persistent max-width="620px">
      <v-form
        ref="formLaborCosts"
        lazy-validation
      >
        <v-card>
          <v-card-title class="headline">Изменить трудозатраты</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-number
              v-model="laborCostData.labor_costs"
              type="number"
              label="Трудозатраты (час)"
              :rules="[v => !!parseFloat(v) || 'Укажите трудозатраты']"
            />
            <tiptap-vuetify
              v-model="laborCostData.comment"
              placeholder="Комментарий"
              :rules="[v => !!v || v === '<p></p>' || 'Добавьте комментарий']"
              :extensions="extensions"
            ></tiptap-vuetify>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="green darken-1" text @click="closeLaborCostsDialog">Отмена</v-btn>
            <v-btn color="green darken-1" :disabled="validLaborCosts" text @click="saveLaborCosts">Добавить</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </v-container>
</template>

<script>
import VComboMultisearch from '../../components/VComboMultisearch'
import VViewTabs from '../../components/VViewTabs'
import WorkflowButton from '../../components/workflow/WorkflowButton'
import ChangeExecutorForm from '../../components/form/ChangeExecutorForm'
import VAttachment from '../../components/VAttachment'
import tiptapextension from '../../mixins/tiptapextension'
import { TiptapVuetify } from 'tiptap-vuetify'
import VNumber from '../../components/VNumber'
import ChangeCustomerForm from '../../components/form/ChangeCustomerForm'
import VSubTask from '@/components/VSubTask'
export default {
  name: 'TaskView',
  components: {
    VSubTask,
    ChangeCustomerForm,
    VNumber,
    VAttachment,
    WorkflowButton,
    VViewTabs,
    VComboMultisearch,
    ChangeExecutorForm,
    TiptapVuetify
  },
  mixins: [tiptapextension],
  data: () => ({
    id: 0,
    loading: false,
    workflow: {
      id: null,
      data_status: [],
      next_data: []
    },
    watchers: [],
    fields: {
      name: '',
      executor: { full_name: '' }, // исполнитель
      customer: { full_name: '' },
      project: { name: '' },
      project_task: { name: '' },
      cost: 0,
      deadline_dt: '',
      start_dt: '',
      status: '',
      status_name: '',
      priority_text: '',
      category: '',
      goal: '',
      result: [{ text: '' }],
      not_allowed: [{ text: '' }],
      description: '',
      is_closed: false,
      whom: { short_name: '' },
      whom_id: null,
      attachments: [],
      appraisals: {},
      watchers: [{ short_name: '' }],
      code_task: '',
      labor_costs: null
    },
    subTaskView: false,
    items: {
      status: [],
      long_goal: [],
      priority: [
        'Высокий',
        'Средний',
        'Низкий'
      ],
      project_tasks: [],
      category: []
    },
    dialogLaborCosts: false,
    laborCostData: {
      labor_costs: 0,
      comment: null
    }
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
    this.getItem()
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    status: function () {
      return this.$store.state.bpm.status
    },
    status_code: function () {
      return this.$store.state.bpm.workflowState.obj.status
    },
    items_users: function () {
      return this.$store.state.task.items.users
    },
    validLaborCosts: function () {
      return !(this.laborCostData.labor_costs && this.laborCostData.comment && this.laborCostData.comment !== '<p></p>')
    }
  },
  methods: {
    async getItem () {
      this.id = parseInt(this.$route.params.id)
      this.loading = true
      this.items = await this.$store.dispatch('task/GET_ITEMS')
      this.$store.dispatch('task/GET_TASK_VIEW', this.id).then(response => {
        this.fields = response.task
        this.subTaskView = response.subtask
      }).finally(() => {
        this.loading = false
      })
    },
    openCommentDialog () {
      this.commentText = ''
      this.$store.commit('comments/OPEN_COMMENT_DIALOG')
    },
    addComment () {
      if (this.$store.state.bpm.workflowComment) {
        const action = this.$store.state.bpm.workflowAction
        this.$store.commit('bpm/SET_COMMENT_ALREADY_ADDED', action)
        this.$store.commit('bpm/RESET_COMMENT_STATE')
        this.$store.dispatch('bpm/processWorkflow', action)
      }
    },
    watcherAdd () {
      this.$store.dispatch('task/POST_WATCHER_ADD', { id: this.id, payload: this.watchers })
        .then(resp => {
          this.$set(this, 'watchers', [])
          this.$set(this.fields, 'watchers', resp.watchers)
          this.$eventHub.$emit('refreshVViewTabs')
        })
    },
    watcherRemove (value) {
      this.$store.dispatch('task/POST_WATCHER_REMOVE', { id: this.id, payload: { id: value } })
        .then(resp => {
          this.$set(this.fields, 'watchers', resp.watchers)
          this.$eventHub.$emit('refreshVViewTabs')
        })
    },
    changeFields (value) {
      const _ = this
      value.forEach(function (item, i, arr) {
        _.fields[item.field] = item.val
      })
    },
    copyTask () {
      this.$store.commit('task/SET_COPY_TASK_ID', this.id)
      this.$router.push({ path: '/tasks/create' })
    },

    createSubTask () {
      this.$store.commit('task/SET_SUBTASK_ID', this.id)
      this.$router.push({ path: '/tasks/create' })
    },

    dialogChangeCustomer () {
      this.$store.dispatch('task/DIALOG_CHANGE_CUSTOMER', [])
    },
    changeCustomer (customerId) {
      const payload = {
        customer_id: customerId
      }
      this.$store.dispatch('task/POST_CHANGE_CUSTOMER', { id: this.id, payload: payload }).then(response => {
        this.fields.customer = response
        this.$eventHub.$emit('refreshVViewTabs')
      }).finally(() => {
        this.$store.commit('task/DIALOG_CHANGE_CUSTOMER_CLOSE')
      })
    },

    dialogChangeExecutor () {
      this.$store.dispatch('task/DIALOG_CHANGE_EXECUTOR', [])
    },
    changeExecutor (executorId) {
      const payload = {
        executor_id: executorId
      }
      this.$store.dispatch('task/POST_CHANGE_EXECUTOR', { id: this.id, payload: payload }).then(response => {
        this.fields.executor = response
        this.$eventHub.$emit('refreshVViewTabs')
      }).finally(() => {
        this.$store.commit('task/DIALOG_CHANGE_EXECUTOR_CLOSE')
      })
    },
    saveLaborCosts () {
      const payload = { ...this.laborCostData, task_id: this.id }
      this.$store.dispatch('task/POST_CHANGE_LABOR_COSTS', payload).then(response => {
        this.fields.labor_costs = this.laborCostData.labor_costs
        this.flashMessage.success({
          title: 'Сохранение',
          message: 'Трудозатраты сохранены'
        })
        this.$eventHub.$emit('refreshVViewTabs')
      }).catch(error => {
        this.flashMessage.error({ title: 'Ошибка', message: 'Трудозатраты не сохранены. ' + error.response.data.error })
      }).finally(() => {
        this.closeLaborCostsDialog()
      })
    },
    closeLaborCostsDialog () {
      this.dialogLaborCosts = false
      this.laborCostData = {
        labor_costs: null,
        comment: null
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  watch: {
    $route: 'getItem'
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'средняя'],
        ['quality', 'качества'],
        ['term', 'срока'],
        ['budget', 'бюджета'],
        ['main', 'общая']
      ])
      return `Оценка ${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>
  .v-chip--disabled {
    opacity: 0 !important;
  }
</style>
