<template>
  <v-dialog v-model="dialog" persistent max-width="560px">
    <v-form
      ref="member_form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>Добавление пользователя</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="memberInner.surname" dense label="Фамилия"></v-text-field>
              <v-text-field v-model="memberInner.name" dense label="Имя"></v-text-field>
              <v-text-field v-model="memberInner.patronymic" dense label="Отчество"></v-text-field>
              <v-text-field v-model="memberInner.email" dense label="E-Mail"></v-text-field>
              <v-masked-input
                v-for="i in phone_count"
                :key="i"
                v-model="memberInner.mobile[i-1]"
                :label="`Телефон #${i}`"
                :mask="'+7 (###) ###-####'"
              >
                <template v-slot:prepend>{{ `${i}.` }}</template>
                <template v-slot:append-outer>
                  <v-btn icon color="primary" @click="addPhone">
                    <v-icon>mdi-plus-thick</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="removePhone(i-1)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-masked-input>
              <date-picker v-model="memberInner.birthday" label="День рождения" clearable @click:clear="memberInner.birthday = null"></date-picker>
              <v-select
                v-model="memberInner.prior_comm"
                :items="prior_comm"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="success" v-if="createPartner" @click="saveMemberAndCreatePartner" :disabled="!valid || isPartner || !createPartner">Сохранить и создать контрагента</v-btn>
          <v-btn small color="error" @click="closeMemberDialog">Отменить</v-btn>
          <v-btn small color="success" @click="saveMember(false)" :disabled="!valid">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DatePicker from '../DatePicker'
import VMaskedInput from '../VMaskedInput'
export default {
  name: 'MemberForm',
  components: { VMaskedInput, DatePicker },
  props: {
    noReturn: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    createPartner: {
      type: Boolean,
      default: false
    },
    partnerId: {
      type: Number,
      default: null
    },
    member: {
      type: Object,
      default: function () {
        return {
          surname: '',
          name: '',
          patronymic: '',
          email: '',
          mobile: [],
          birthday: null,
          prior_comm: undefined
        }
      }
    }
  },
  data: function () {
    return {
      isPartner: false, // true - контрагент уже создан
      prior_comm: [
        { value: 'email', text: 'E-Mail' },
        { value: 'phone', text: 'Телефон' }
      ],
      memberInner: { ...this.member },
      valid: true,
      ruleRequired: [v => !!v || 'Обязательное поле']
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
    dialog: function () {
      return this.$store.state.user.dialogMember
    },
    phone_count: function () {
      return (this.memberInner.mobile && this.memberInner.mobile.length > 1) ? this.memberInner.mobile.length : 1
    }
  },
  methods: {
    saveMember (create = false) {
      let payload
      if (!this.$refs.member_form.validate()) return
      if (this.partnerId) {
        payload = { ...this.memberInner, noreturn: this.noReturn, partner_id: this.partnerId }
      } else {
        payload = { ...this.memberInner, noreturn: this.noReturn }
      }
      this.$store.dispatch('user/POST_MEMBER', payload).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Пользователь успешно сохранен.' })
        if (create) {
          this.$store.commit('partners/CREATE_MEMBER', response.item)
          this.$router.push({ name: 'PartnersCreate' })
        } else {
          this.$eventHub.$emit('onPostMember', response)
        }
      }).finally(() => {
        this.closeMemberDialog()
      })
    },
    saveMemberAndCreatePartner () {
      this.saveMember(true)
    },
    closeMemberDialog () {
      this.$store.commit('user/CLOSE_DIALOG_MEMBER')
    },
    addPhone () {
      this.memberInner.mobile.push('')
    },
    removePhone (val) {
      if (this.phone_count > 1) this.memberInner.mobile.splice(val, 1)
    }
  },
  watch: {
    memberInner: async function (val) {
      if (val.id) {
        this.isPartner = await this.$store.dispatch('user/GET_CHECK_PARTNER_MEMBER', val.id)
      } else {
        this.isPartner = false
      }
    },
    member: function (val) {
      this.memberInner = { ...val }
    }
  }
}
</script>

<style scoped>

</style>
