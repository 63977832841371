<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Отчет по задачам"
    >
      <v-row>
        <v-col cols="1">
          <v-text-field
            v-model="year"
            type="number"
            label="Год"
          />
        </v-col>
        <v-col cols="1">
          <v-select
            v-model="period"
            :items="periodList"
            item-text="name"
            item-value="id"
            label="Период"
            return-object
          />
        </v-col>
        <v-col cols="1">
          <v-select
            v-model="numPeriod"
            item-value="value"
            item-text="text"
            :items="getNumPeriod"
            label="Номер периода"
          />
        </v-col>
        <v-col cols="1" class="d-flex align-center">
          <v-btn outlined small color="primary" @click="queryReport" :disabled="makeDisable">Создать</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
          >
            <template v-slot:item.period="{ item }">
              {{ item.period | mapPeriod }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="downloadFile(item.file_name)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn icon @click="deleteFile(item.file_name)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.created_dt="{ item }">
              {{ item.created_dt | moment('timezone', 'Asia/Yekaterinburg', 'L LT') }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </base-card>
    <v-dialog v-model="confirmCreateDialog" max-width="560px" persistent>
      <v-card>
        <v-card-title>Исполнители</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>Отчет существует. Пересоздать?</v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="btnNo">Нет</v-btn>
          <v-btn class="success" small @click.stop="btnYes">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    items: [],
    year: null,
    period: null,
    numPeriod: null,
    periodList: [
      {
        id: 'month',
        name: 'Месяц',
        numPeriod:
          [
            { value: 1, text: 'Январь' },
            { value: 2, text: 'Февраль' },
            { value: 3, text: 'Март' },
            { value: 4, text: 'Апрель' },
            { value: 5, text: 'Май' },
            { value: 6, text: 'Июнь' },
            { value: 7, text: 'Июль' },
            { value: 8, text: 'Август' },
            { value: 9, text: 'Сентябрь' },
            { value: 10, text: 'Октябрь' },
            { value: 11, text: 'Ноябрь' },
            { value: 12, text: 'Декабоь' }
          ]
      },
      { id: 'quarter', name: 'Квартал', numPeriod: [1, 2, 3, 4] },
      { id: 'year', name: 'Год' }
    ],
    headers: [
      { text: 'Год', value: 'year' },
      { text: 'Период', value: 'period' },
      { text: 'Номер периода', value: 'num_period' },
      { text: 'Время создания', value: 'created_dt' },
      { value: 'action' }
    ],
    confirmCreateDialog: false
  }),
  mounted () {
    this.getData()
  },
  computed: {
    getNumPeriod: function () {
      return this.period?.numPeriod
    },
    makeDisable: function () {
      if (!this.year || !this.period) return true
      if (this.year && this.period && (
        (['month', 'quarter'].indexOf(this.period.id) !== -1 && this.numPeriod) ||
        (this.period.id === 'year')
      )) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('ticket/GET_REPORT_LIST').then(response => {
        this.items = response
      })
    },
    queryReport () {
      let i
      if (this.year === 'year') {
        i = this.items.find(item => (item.year === parseInt(this.year) && item.period === this.period.id))
      } else {
        i = this.items.find(item => (item.year === parseInt(this.year) && item.period === this.period.id && item.num_period === this.numPeriod))
      }
      if (i) {
        this.confirmCreateDialog = true
      } else {
        this.makeReport()
      }
    },
    makeReport () {
      const payload = {
        year: this.year,
        period: this.period.id,
        num_period: this.numPeriod
      }
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('ticket/POST_REPORT', payload).then(response => {
        this.items = response.data
        this.flashMessage.success({ title: 'Отчет', message: response.message })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.title
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    downloadFile (file) {
      this.$store.dispatch('ticket/GET_DOWNLOAD_REPORT', { filename: file }).then(response => {
        const headers = response.headers
        const blob = new Blob([response.data], { type: headers['content-type'] })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        link.click()
      })
    },
    deleteFile (file) {
      this.$store.dispatch('ticket/DELETE_REPORT', { filename: file }).then(response => {
        this.items = response
      })
    },
    btnYes () {
      this.confirmCreateDialog = false
      this.makeReport()
    },
    btnNo () {
      this.confirmCreateDialog = false
    }
  },
  filters: {
    mapPeriod (pure) {
      const map = new Map([
        ['year', 'Год'],
        ['half_year', 'Пол года'],
        ['quarter', 'Квартал'],
        ['month', 'Месяц']
      ])
      return map.get(pure)
    }
  }
}
</script>

<style scoped>

</style>
