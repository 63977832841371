<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :search-input.sync="search"
    :filter="filter"
    hide-no-data
    :loading="loading"
    @click:clear="clear"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-slot:item="{ parent, item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" two-line>
        <v-list-item-avatar v-if="avatar">
          <img :src="(item[itemAvatar] === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${item[itemAvatar]}`">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="item[parent.itemText]"></v-list-item-title>
          <v-list-item-subtitle v-html="item[itemDesc]"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'VAutocompleteMultisearch',
  props: {
    itemDesc: {
      type: String,
      default: 'description'
    },
    avatar: {
      type: Boolean,
      default: false
    },
    itemAvatar: {
      type: String,
      default: 'avatar'
    },
    action: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false,
    items: [],
    search: null,
    select: null
  }),
  created () {
    this.querySelections(this.$attrs.value, true)
  },
  computed: {
    valueIdx: function () {
      return this.$attrs.value
    }
  },
  methods: {
    filter (item, queryText, itemText) {
      return (
        item[this.$attrs['item-text']].toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || (
          item[this.itemDesc] &&
          item[this.itemDesc].toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        )
      )
    },
    querySelections (val, isIndex = false) {
      if (!this.action) return
      this.loading = true
      let payload
      if (isIndex && val && Number.isInteger(val)) {
        payload = { index: this.$attrs.value }
      } else {
        payload = { term: val || '' }
      }
      this.$store.dispatch(this.action, payload).then(response => {
        this.items = response
      }).catch(() => {
        this.items = []
      }).finally(() => {
        this.loading = false
      })
    },
    clear () {
      this.querySelections('')
    }
  },
  watch: {
    search (val) {
      val && val !== this.$attrs.value && this.querySelections(val)
    },
    valueIdx (val) {
      this.querySelections(val, true)
    }
  }
}
</script>

<style scoped>

</style>
