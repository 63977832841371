import { Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, /* Link, */ Blockquote, HardBreak, /* HorizontalRule, */ History } from 'tiptap-vuetify'
export default {
  data: () => ({
    extensions: [
      History,
      Blockquote,
      // Link,
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [2, 3]
        }
      }],
      Code,
      // HorizontalRule,
      Paragraph,
      HardBreak
    ]
  })
}
