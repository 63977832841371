<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Редактирование задачи"
    >
      <v-row>
        <v-col cols="8" />
        <v-col cols="4">
          <v-select
            v-model="fields.status"
            :items="items_status"
            item-value="code"
            item-text="name"
            label="Статус"
            readonly
            dense
          />
        </v-col>
      </v-row>
      <task-edit-form :fields="fields" @onTaskSave="saveData"/>
<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <v-btn color="primary" class="ma-2" :disabled="btn_disabled" @click="saveData">Сохранить</v-btn>-->
<!--          <v-btn color="warning" class="ma-2" @click="$router.go(-1)">Отменить</v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </base-card>
  </v-container>
</template>

<script>
import TaskEditForm from '../../components/form/TaskEditForm'
export default {
  name: 'TaskEdit',
  components: { TaskEditForm },
  data: () => ({
    fields: {},
    task_id: null
  }),
  mounted () {
  },
  created () {
    this.task_id = this.$route.params.id
    this.getItems()
  },
  computed: {
    items_status: function () {
      return this.$store.state.task.items.status
    }
  },
  methods: {
    async getItems () {
      await this.$store.dispatch('task/GET_ITEMS')
      const task = await this.$store.dispatch('task/GET_TASK', this.task_id)
      this.fields = task.task
      if (this.fields.not_allowed.length === 0) {
        this.fields.not_allowed = [{ text: '' }]
      }
      if (this.fields.project_id !== null) {
        await this.$store.dispatch('task/GET_PROJECT_TASKS', this.fields.project_id || 0)
      }
    },
    async saveData (val) {
      if (!val.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      const id = this.task_id
      const payload = this.fields
      await this.$store.dispatch('task/PUT_TASK', { id, payload })
        .then(() => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Задача успешно сохранена' })
          this.$router.push({ path: `/tasks/${this.task_id}/view` })
        })
        .catch(error => {
          const message = error.response.data.error || ''
          const title = error.response.data.message
          this.flashMessage.error({ title: title, message: `Ошибка сохранения задачи. ${message}` })
        })
        .finally(() => { this.$store.commit('OVERLAY_OFF') })
    }
  }
}
</script>

<style scoped>

</style>
