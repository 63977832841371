<template>
  <v-dialog v-model="dialog" persistent max-width="520px">
    <v-form
      ref="changedate_form"
      v-model="validDateChange"
      lazy-validation
    >
      <v-card>
        <v-card-title class="headline">Изменить период</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <date-picker
                v-model="newStart"
                label="Новая дата начала"
                :max="newDeadline"
                :rules="[v => !!v || 'Обязательное поле']"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <date-picker
                v-model="newDeadline"
                label="Новая дата окончания"
                :min="newStart"
                :rules="[v => !!v || 'Обязательное поле']"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="commentText"
            outlined
            label="Комментарий"
            :rules="[v => !!v || 'Добавьте комментарий']"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeDialog">Отмена</v-btn>
          <v-btn color="green darken-1" text @click="goProcess">Установить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChangeDeadline',
  props: {
    id: {
      default: null,
      type: Number
    },
    type: {
      default: '',
      type: String
    },
    start_dt: String,
    deadline_dt: String
  },
  data: () => ({
    validDateChange: true
  }),
  created () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  computed: {
    dialog: function () {
      return this.$store.state.bpm.dateChange.dateChangeDialog
    },
    newStart: {
      get: function () {
        return this.$store.state.bpm.dateChange.newStart
      },
      set: function (value) {
        this.$store.commit('bpm/SET_NEW_DATE', { field: 'newStart', value: value })
      }
    },
    newDeadline: {
      get: function () {
        return this.$store.state.bpm.dateChange.newDeadline
      },
      set: function (value) {
        this.$store.commit('bpm/SET_NEW_DATE', { field: 'newDeadline', value: value })
      }
    },
    commentText: {
      get: function () {
        return this.$store.state.bpm.commentText
      },
      set: function (value) {
        this.$store.commit('bpm/SET_COMMENT', value)
      }
    }
  },
  methods: {
    goProcess () {
      if (!this.$refs.changedate_form.validate()) {
        return
      }
      const i = this.$store.state.bpm.workflowAction
      if (this.$store.state.bpm.workflowState.workflow.data_status[i].next === 'deadline_matching') {
        this.$store.commit('bpm/SET_DATE')
        this.$store.dispatch('bpm/processWorkflow', i)
          .then(resp => {
            this.$store.commit('bpm/CLOSE_DATE_CHANGE_DIALOG')
            this.$store.commit('bpm/RESET_DEADLINE_STATE')
            this.$emit('changeFields', [
              { field: 'deadline_dt', val: resp.deadline_dt },
              { field: 'start_dt', val: resp.start_dt }
            ])
          })
        return
      }
      const payload = {
        id: this.id,
        type: this.type
      }
      this.$store.dispatch('bpm/POST_CHANGE_DATA', { payload, action: 'timeline' })
        .then(resp => {
          this.$store.dispatch('comments/GET_COMMENTS', { id: this.id, type: this.type })
          this.$store.dispatch('comments/GET_HISTORIES', { id: this.id, type: this.type })
          this.$store.commit('bpm/CLOSE_DATE_CHANGE_DIALOG')
          this.$store.commit('bpm/RESET_DEADLINE_STATE')
          this.$emit('changeFields', [
            { field: 'deadline_dt', val: resp.deadline_dt },
            { field: 'start_dt', val: resp.start_dt }
          ])
          this.flashMessage.success({ title: 'Сохранение', message: this.$store.state.bpm.flashMessage })
        })
    },
    closeDialog () {
      this.$store.commit('bpm/CLOSE_DATE_CHANGE_DIALOG')
    }
  },
  watch: {
    start_dt: function (value) {
      this.newStart = value
    },
    deadline_dt: function (value) {
      this.newDeadline = value
    }
  }
}
</script>

<style scoped>

</style>
