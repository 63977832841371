<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Создание заявки"
    >
      <v-stepper
        v-model="step"
      >
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Контрагент / Объект</v-stepper-step><v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">Заявка</v-stepper-step><v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">Группа</v-stepper-step>
<!--          <v-stepper-step :complete="step > 4" step="4">Канал поступления</v-stepper-step><v-divider></v-divider>-->
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="ticket_form1" v-model="valid1" lazy-validation>
              <v-row class="mt-1">
                <v-col cols="12" lg="6">
                  <v-select
                    v-model="fields.declarer_id"
                    :items="itemsObject.declarer"
                    item-value="id"
                    item-text="name"
                    label="Заявитель"
                    outlined
                    dense
                    :rules="rules.required"
                  />
                  <v-combo-multisearch
                    v-model="fields.partner_id"
                    :items="itemsObject.partners"
                    item-value="id"
                    item-text="name_with_debtor"
                    item-desc="name_additional"
                    label="Контрагент"
                    outlined
                    dense
                    @change="changeObject('partner_id')"
                    :rules="rules.required"
                  />
                  <span class="d-flex justify-space-between">
                    <v-combo-description
                      v-model="fields.customer_id"
                      :items="itemsObject.contacts"
                      item-value="member.id"
                      item-text="member.full_name"
                      item-desc="role"
                      label="Контактное лицо"
                      outlined
                      dense
                      :rules="rules.required"
                    />
                    <v-btn
                      v-if="fields.partner_id"
                      icon
                      @click="addMember"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </span>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-combo-multisearch
                    v-model="fields.facility_id"
                    :items="itemsObject.facilities"
                    item-value="id"
                    item-text="address"
                    item-desc="name"
                    label="Объект недвижимости"
                    outlined
                    dense
                    @change="changeObject('facility_id')"
                    :rules="rules.required"
                  />
                  <v-autocomplete
                    v-model="fields.flat_id"
                    :items="itemsObject.flats"
                    item-value="id"
                    item-text="name"
                    label="Квартира / Офис / Машиноместо"
                    outlined
                    dense
                    @change="changeObject('flat_id')"
                    :rules="rules.required"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>{{ item.name }}</v-list-item-content>
                      <v-list-item-action v-if="item.plan">
                        <v-btn icon x-small @click.stop="openPlan(item.plan)">
                          <v-icon>mdi-floor-plan</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-btn color="error" @click="clearForm1">Очистить</v-btn>
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn @click="$router.push({ name: 'TicketList' })">Отмена</v-btn>
                  <v-btn color="success" @click="nextStep(2)" :disabled="!valid1">Дальше</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="ticket_form2" v-model="valid2" lazy-validation>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-text-field
                    v-model="fields.name"
                    outlined
                    dense
                    label="Наименование"
                    :rules="rules.required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-select
                    v-model="fields.priority"
                    :items="itemsObject.priority"
                    item-value="idx"
                    item-text="name"
                    label="Приоритет"
                    outlined
                    dense
                    :rules="rules.required"
                  />
                </v-col>
<!--                <v-col cols="12" lg="6">-->
<!--                  <date-picker-->
<!--                    v-model="fields.deadline_dt"-->
<!--                    label="Плановая дата выполнения"-->
<!--                    :min="$moment().format('YYYY-MM-DD')"-->
<!--                    outlined-->
<!--                    dense-->
<!--                    :rules="rules.required"-->
<!--                  />-->
<!--                </v-col>-->
                <v-col cols="12" lg="6">
                  <v-combo-description
                    v-model="fields.type_id"
                    :items="itemsObject.type"
                    item-value="id"
                    item-text="name"
                    item-desc="description"
                    label="Тип заявки"
                    outlined
                    dense
                    :rules="rules.required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="fields.description"
                    label="Описание"
                    outlined
                    dense
                    :rules="rules.required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" lg="5">
                  <v-combo-multisearch
                    v-model="fields.executor_id"
                    :items="executorList"
                    item-text="full_name"
                    item-value="id"
                    item-desc="position"
                    label="Руководитель"
                    outlined
                    dense
                    :rules="rules.required"
                  />
                </v-col>
                <v-col cols="2" lg="1">
                  <v-switch
                    v-model="allExecutor"
                    label="Все"
                    dense
                  />
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn>Отмена</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="step=1" class="mr-1">Назад</v-btn>
                  <v-btn color="success" @click="nextStep(3)" :disabled="!valid2">Дальше</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form ref="ticket_form3" v-model="valid3" lazy-validation>
              <v-row class="mt-1">
                <v-col cols="12" lg="6">
                  <v-select
                    v-model="groupObject"
                    :items="itemsObject.groups"
                    item-value="id"
                    item-text="name"
                    label="Группа"
                    return-object
                    outlined
                    dense
                    :rules="rules.required"
                  ></v-select>
                  <v-select
                    v-model="fields.subgroup_id"
                    :items="subgroup"
                    item-value="id"
                    item-text="name"
                    label="Подгруппа"
                    outlined
                    dense
                    :rules="rules.required"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-combo-description
                    v-model="fields.channel_id"
                    :items="itemsObject.channels"
                    item-value="id"
                    item-text="name"
                    item-desc="description"
                    label="Канал поступления заявки"
                    outlined
                    dense
                    :rules="rules.required"
                  ></v-combo-description>
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn>Отмена</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="step=2" class="mr-1">Назад</v-btn>
                  <v-btn color="success" @click="saveData" class="mr-1" :disabled="!valid3">Сохранить</v-btn>
                  <v-btn color="success" @click="saveAndCreateData" :disabled="!valid3">Сохранить и Создать</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </base-card>
    <v-plan :path-plan="showPlan" @closePlan="showPlan = null"></v-plan>
    <member-form
      :partner-id="fields.partner_id"
      no-return
    />
  </v-container>
</template>

<script>
import VComboDescription from '../../components/VComboDescription'
import VComboMultisearch from '../../components/VComboMultisearch'
import VPlan from '../../components/reference/floor/VPlan'
import MemberForm from '../../components/reference/MemberForm'
export default {
  name: 'Create',
  components: { VPlan, VComboMultisearch, VComboDescription, MemberForm },
  data: () => ({
    step: 1,
    showPlan: null,
    fields: {
      declarer_id: undefined,
      channel_id: undefined,
      partner_id: undefined,
      customer_id: undefined,
      subgroup_id: undefined,
      priority: 30,
      type_id: undefined,
      name: '',
      deadline_dt: '',
      description: '',
      executor_id: undefined,
      facility_id: undefined,
      flat_id: undefined
    },
    groupObject: undefined,
    allExecutor: false,
    itemsObject: {
      partners: [],
      contacts: [],
      facilities: [],
      flats: [],
      groups: [],
      channels: [],
      type: [],
      declarer: [],
      executor: [],
      priority: []
    },
    queryTermFlat: '',
    flatType: {
      flat: 'квартира',
      office: 'офис',
      not_residential: 'нежилое',
      parking_place: 'машиноместо',
      mop: 'МОП'
    },
    valid1: true,
    valid2: true,
    valid3: true,
    rules: {
      required: [v => !!v || 'Обязательное поле']
    }
  }),
  created () {
    this.getData()
    this.$eventHub.$on('back', this.goBack)
    this.$eventHub.$on('onPostMember', this.onPostMember)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
    this.$eventHub.$off('onPostMember', this.onPostMember)
  },
  computed: {
    channel: function () {
      return this.$store.state.ticket.items.channel
    },
    declarer: function () {
      return this.$store.state.ticket.items.declarer
    },
    partners: function () {
      return this.$store.state.ticket.items.partners // .map(pick(['id', 'name']))
    },
    subgroup: function () {
      return (this.groupObject) ? this.groupObject.subgroup : []
    },
    type: function () {
      return this.$store.state.ticket.items.type
    },
    executorList: function () {
      if (this.allExecutor) {
        return this.itemsObject.executor
      } else {
        return this.itemsObject.executor.filter(
          item => item.department.find(dep => dep.params.ticket === true)?.params.ticket === true && item.roles.find(role => role.name === 'manager')
        )
      }
    }
  },
  methods: {
    async getData () {
      this.itemsObject = await this.$store.dispatch('ticket/GET_ITEMS')
    },
    saveData () {
      if (!this.$refs.ticket_form3.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('ticket/POST_TICKET', this.fields).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Заявка создана успешно.' })
        this.$router.push({ path: `/tickets/${response.id}/view` })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    saveAndCreateData () {
      if (!this.$refs.ticket_form3.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('ticket/POST_TICKET', this.fields).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Заявка создана успешно. Можно заводить следующую' })
        this.fields = {
          declarer_id: undefined,
          channel_id: undefined,
          partner_id: undefined,
          customer_id: undefined,
          subgroup_id: undefined,
          priority: 30,
          type_id: undefined,
          name: '',
          deadline_dt: '',
          description: '',
          executor_id: undefined,
          facility_id: undefined,
          flat_id: undefined
        }
        this.step = 1
        this.getData()
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    async clearForm1 () {
      this.fields.partner_id = undefined
      this.fields.facility_id = undefined
      this.fields.flat_id = undefined
      this.fields.customer_id = undefined
      await this.getData()
    },
    changeObject (name) {
      const payload = {}
      const self = this
      const fieldCorrect = {
        partners: 'partner_id',
        contacts: 'customer_id',
        facilities: 'facility_id',
        flats: 'flat_id'
      }
      // if (name === 'partner_id' && !this.fields[name]) {
      //   this.fields.facility_id = undefined
      //   this.fields.flat_id = undefined
      //   this.fields.customer_id = undefined
      // }
      // if (name === 'facility_id' && !this.fields[name]) {
      //   this.fields.partner_id = undefined
      //   this.fields.customer_id = undefined
      //   this.fields.flat_id = undefined
      // }
      payload[name] = this.fields[name]
      switch (name) {
        case 'partner_id':
          if (this.fields.facility_id) payload.facility_id = this.fields.facility_id
          break
        case 'facility_id':
          if (this.fields.partner_id) payload.partner_id = this.fields.partner_id
      }
      this.$store.dispatch('ticket/GET_OBJECT', payload)
        .then(response => {
          this.itemsObject = { ...this.itemsObject, ...response }
          Object.keys(this.itemsObject).forEach(function (item, idx, array) {
            if (self.itemsObject[item].length === 0) self.fields[fieldCorrect[item]] = undefined
            // if (self.itemsObject[item].length === 1) self.fields[fieldCorrect[item]] = self.itemsObject[item][0].id
            // if (self.itemsObject[item].length === 1) self.$set(self.fields, fieldCorrect[item], self.itemsObject[item][0].id)
          })
        })
    },
    addMember () {
      this.$store.commit('user/OPEN_DIALOG_MEMBER')
    },
    onPostMember (item) {
      this.changeObject('partner_id')
      this.fields.customer_id = item.user_id
    },
    nextStep (step) {
      if (!this.$refs[`ticket_form${step - 1}`].validate()) {
        return
      }
      this.step = step
    },
    openPlan (value) {
      this.showPlan = value
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
