<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item="{ parent, item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" two-line>
        <v-list-item-content>
          <v-list-item-title v-html="getObjectValue(item, parent.itemText)"></v-list-item-title>
          <v-list-item-subtitle v-html="item[itemDesc]"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { getObjectValueByPath } from 'vuetify/es5/util/helpers'
export default {
  name: 'v-combo-description',
  props: {
    itemDesc: {
      type: String,
      default: 'description'
    }
  },
  data: () => ({}),
  created () {},
  mounted () {},
  methods: {
    getObjectValue: function (item, path) {
      return getObjectValueByPath(item, path)
    }
  }
}
</script>

<style scoped>

</style>
