<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-lg-3 py-1"
      :title="header"
    >
      <v-row>
        <v-col cols="4">
          <v-select
            v-if="visibleWorkflow"
            v-model="process_id"
            :items="items_process"
            item-value="id"
            item-text="name"
            label="Бизнес процесс"
            dense
          />
          <span v-if="isCreateSubtask" class="d-flex justify-start">
            <span class="mr-5">
              <span>Родительская задача: </span>
              <v-chip
                label
                link
                small
                @click="$router.push({ path: `/tasks/${fields.parent}/view`})"
              >{{ fields.code_task }}</v-chip>
            </span>
            <v-select
              v-model="fields.subtask_type"
              :items="subTaskTypeList"
              dense></v-select>
          </span>
        </v-col>
        <v-col cols="4" />
        <v-col cols="4">
          <v-select
            v-model="fields.status"
            :items="items_status"
            item-value="code"
            item-text="name"
            label="Статус"
            readonly
            dense
          />
        </v-col>
      </v-row>

      <task-edit-form :fields="fields" @onTaskSave="saveData" @processId="change_category"/>

    </base-card>
  </v-container>
</template>

<script>
import TaskEditForm from '../../components/form/TaskEditForm'
export default {
  name: 'TaskCreate',
  components: { TaskEditForm },
  data: () => ({
    fields: {
      name: '',
      executor_id: null, // исполнитель
      customer_id: null,
      project_id: undefined,
      project_task_id: undefined,
      deadline_dt: '',
      start_dt: '',
      status: 'draft',
      priority: 30,
      labor_costs: 0,
      category: 'task',
      goal: '',
      tags: [],
      result: [
        { text: '' }
      ],
      not_allowed: [
        { text: '' }
      ],
      description: '',
      watchers: [],
      subtask_type: null
    },
    process_id: null,
    isCreateSubtask: false,
    subTaskTypeList: [
      { value: 0, text: 'Связанная подзадача' },
      { value: 1, text: 'Блокировка родительской задачи' }
    ]
  }),
  created () {
    this.getItems()
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    items_status: function () {
      return this.$store.state.task.items.status
    },
    items_process: function () {
      return this.$store.state.task.items.process
    },
    visibleWorkflow: function () {
      return this.$auth.check('root')
    },
    header: function () {
      return (this.isCreateSubtask) ? 'Создание новой подзадачи' : 'Создание новой задачи'
    }
  },
  methods: {
    async getItems () {
      // TODO вынести получение процесса по умолчанию в отдельный метод на бэке
      await this.$store.dispatch('task/GET_ITEMS')
      this.process_id = this.items_process.find(item => item.id === this.$store.state.task.items.default_process).id
      if (this.$store.state.task.isFromPlan) {
        this.fields.start_dt = this.$store.state.task.fromPlan.start_dt
        this.fields.deadline_dt = this.$store.state.task.fromPlan.end_dt
        this.fields.name = this.$store.state.task.fromPlan.name
        this.fields.project_id = this.$store.state.task.fromPlan.project_id
        this.fields.project_task_id = this.$store.state.task.fromPlan.project_task_id
        await this.$store.dispatch('task/GET_PROJECT_TASKS', this.fields.project_id || 0)
        this.$store.commit('task/CLEAR_AFTER_CREATE_FROM_PLAN')
      }
      if (this.$store.state.task.copyTaskId !== null) {
        // создание копии задачи
        const task = await this.$store.dispatch('task/GET_TASK', this.$store.state.task.copyTaskId)
        this.fields = task.task
        this.fields.status = 'draft'
        this.fields.category = 'task'
        this.fields.start_dt = ''
        this.fields.deadline_dt = ''
        this.fields.project_id = undefined
        this.fields.project_task_id = undefined
        this.fields.customer_id = this.$auth.user().id
        this.fields.is_closed = false
        delete this.fields.id
        if (this.fields.not_allowed.length === 0) {
          this.fields.not_allowed = [{ text: '' }]
        }
        if (this.fields.project_id !== null) {
          await this.$store.dispatch('task/GET_PROJECT_TASKS', this.fields.project_id || 0)
        }
        if (this.$store.state.task.subTask) {
          this.isCreateSubtask = true
          this.fields.subtask_type = 0
          this.fields.parent = this.$store.state.task.copyTaskId
          this.$store.commit('task/CLEAR_SUBTASK')
        } else {
          this.$store.commit('task/CLEAR_COPY_TASK')
        }
      } else {
        this.fields.customer_id = this.$auth.user().id
      }
    },
    async saveData (val) {
      if (!val.validate()) {
        return
      }
      this.$store.commit('OVERLAY_ON')
      const payload = this.fields
      payload.process_id = this.process_id
      await this.$store.dispatch('task/POST_TASK', payload).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Задача создана' })
        this.$router.push({ path: `/tasks/${response.id}/view` })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      }).finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    change_category (value) {
      this.process_id = value
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
