<template>
  <v-dialog v-model="dialogShow" persistent max-width="560px">
    <v-card>
      <v-card-title>Смена исполнителя</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-combo-multisearch
              v-model="executorId"
              :items="executorList"
              item-text="full_name"
              item-value="id"
              item-desc="position"
              label="Исполнитель"
              outlined
              dense
            ></v-combo-multisearch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn class="primary mr-2" small @click.stop="closeDialog">Отмена</v-btn>
        <v-btn class="success" small @click.stop="saveData">Сменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VComboMultisearch from '../VComboMultisearch'
export default {
  name: 'ChangeExecutorForm',
  components: { VComboMultisearch },
  props: {
    executorId: Number,
    id: Number,
    executor: {
      type: Array,
      default: null
    }
  },
  data: () => ({
  }),
  computed: {
    dialogShow: function () {
      return this.$store.state.task.changeExecutorDialog
    },
    executorList: function () {
      return this.$store.state.task.executorList
    }
  },
  methods: {
    saveData () {
      this.$emit('changeExecutor', this.executorId)
    },
    closeDialog () {
      this.$store.commit('task/DIALOG_CHANGE_EXECUTOR_CLOSE')
    }
  }
}
</script>

<style scoped>

</style>
