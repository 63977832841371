<template>
  <div v-if="!obj.is_closed" class="d-flex justify-end flex-wrap flex-lg-nowrap">
    <div
      v-for="(item, i) in workflow.data_status"
      :key="i"
    >
      <v-btn
        v-if="item.visible.indexOf($auth.user().id) !== -1 || item.visible.indexOf($auth.user().repl_id) !== -1"
        :disabled="item.disable"
        class="ma-1" :color="item.color" small @click="processWorkflow(i)"
      >{{ item.button }}</v-btn>
    </div>
    <change-deadline
      :id="object_id"
      :type="type"
      :start_dt="start_dt"
      :deadline_dt="deadline_dt"
      @changeFields="changeFields"
    />
    <appraisal></appraisal>
    <reason></reason>
  </div>
</template>

<script>
import ChangeDeadline from './ChangeDeadline'
import Appraisal from './Appraisal'
import Reason from './Reason'
export default {
  name: 'WorkflowButton',
  components: { Reason, Appraisal, ChangeDeadline },
  props: {
    type: {
      default: null,
      type: String
    },
    object_id: {
      default: null,
      type: Number
    },
    date_obj: Object
  },
  data: () => ({
  }),
  created () {
    this.getData()
  },
  computed: {
    workflow: function () {
      return this.$store.state.bpm.workflowState.workflow
    },
    obj: function () {
      return this.$store.state.bpm.workflowState.obj
    },
    change_params: function () {
      return this.$store.state.bpm.workflowState.obj.change_params
    },
    start_dt: function () {
      return (this.date_obj) ? this.date_obj.start_dt : ''
    },
    deadline_dt: function () {
      return (this.date_obj) ? this.date_obj.deadline_dt : ''
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('bpm/GET_WORKFLOW', { id: this.object_id, type: this.type })
    },
    processWorkflow (i) {
      this.$store.dispatch('bpm/processWorkflow', i).catch(error => {
        // const message = error.response.data.error || ''
        // const title = error.response.data.message
        // this.flashMessage.error({ title: title, message: `Ошибка сохранения задачи. ${message}` })
        console.log('Error in component', error.response.data)
      })
    },
    changeFields (val) {
      this.$emit('changeFields', val)
    }
  },
  watch: {
    change_params: function () {
      if (this.change_params && this.change_params.length > 0) {
        this.$emit('changeFields', this.$store.state.bpm.workflowState.obj.change_params)
      }
    },
    object_id: function () {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>
