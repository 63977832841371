<template>
  <v-dialog v-model="dialog" persistent max-width="520px">
    <v-form
      ref="reason_form"
    >
      <v-card>
        <v-card-title class="headline">{{ reasonHeader }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model="reason"
            :items="reasonList"
            item-value="id"
            item-text="text"
          />
          <v-text-field
            v-model="other"
            :disabled="disableOther"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeDialog">Нет</v-btn>
          <v-btn color="green darken-1" text @click="goProcess" :disabled="valid">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'Reason',
  data: () => ({
    reason: null,
    other: null,
    page: ''
  }),
  created () {
    this.page = this.$route.name
  },
  mounted () {},
  computed: {
    dialog: function () {
      return this.$store.state.bpm.reason.dialog
    },
    disableOther: function () {
      return this.reason !== 255
    },
    valid: function () {
      if (this.reason >= 0 && this.reason < 255) return false
      if (this.reason === 255 && this.other) return false
      return true
    },
    reasonHeader: function () {
      if (this.page === 'ProjectView') {
        return 'Отмена проекта'
      } else {
        return 'Отмена заявки'
      }
    },
    reasonList: function () {
      if (this.page === 'ProjectView') {
        return [
          { id: 100, text: 'Отмена заказчиком' },
          { id: 101, text: 'Потерял актуальность' },
          { id: 255, text: 'Другое' }
        ]
      } else {
        return [
          { id: 0, text: 'Отказ заказчика' },
          { id: 1, text: 'Решение другими способами' },
          { id: 255, text: 'Другое' }
        ]
      }
    }
  },
  methods: {
    goProcess () {
      const payload = {
        reason: this.reason,
        text: this.reasonList.find(item => item.id === this.reason).text,
        other: this.other
      }
      const index = this.$store.state.bpm.reason.index
      this.$store.commit('bpm/SET_REASON', payload)
      this.$store.dispatch('bpm/processWorkflow', index)
    },
    closeDialog () {
      this.$store.commit('bpm/RESET_REASON')
    }
  }
}
</script>

<style scoped>

</style>
