import pickBy from 'lodash/pickBy'
export default {
  data: () => ({
    sortBy: 'id',
    sortDesc: false,
    footerProps: {
      itemsPerPageOptions: [5, 10, 25, 50],
      showFirstLastPage: true
    },
    items: [],
    params: {},
    meta: {
      total: 0
    }
  }),
  computed: {
    total: function () {
      return this.meta.total
    },
    getParams () {
      return {
        ...this.getFilter(),
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        sort_by: this.pagination.sortBy[0],
        sort_desc: this.pagination.sortDesc[0]
      }
    }
  },
  methods: {
    update () {
      this.filterData()
    },
    getFilter () {
      return pickBy(this.params, Boolean)
    },
    setParams () {
      const payload = {}
      this.filters.forEach(function (i, idx, array) {
        payload[i.field] = i.val
      })
      this.params = payload
    },
    resetPage (items) {
      this.filters = items
      this.pagination.page = 1
      this.filterData()
    }
  },
  watch: {
    pagination: {
      handler () {
        this.update()
      },
      deep: true
    }
  }
}
