<template>
  <div>
    <v-card class="mt-3" v-if="viewComments || viewHistory">
      <v-tabs>
        <v-tab key="comments" v-if="viewComments">Комментарии</v-tab>
        <v-tab key="history" v-if="viewHistory">История</v-tab>
        <v-tab-item key="comments" v-if="viewComments" class="pa-2">
          <v-card
            v-for="(item, i) in comments"
            :key="i"
            class="mb-1 elevation-1"
          >
            <v-card-title class="text-h6" v-text="item.user.full_name"></v-card-title>
            <v-card-subtitle class="text-subtitle-2">{{ item.created_dt | moment('timezone', 'Asia/Yekaterinburg', 'LLL') }}</v-card-subtitle>
            <v-card-text class="text-body-1 tiptap-vuetify-editor__content" v-html="item.text"></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="history" v-if="viewHistory" class="pa-2">
          <v-card
            v-for="(item, i) in histories"
            :key="i"
            class="mb-1 elevation-1"
          >
            <v-card-title v-text="item.title"/>
            <v-card-subtitle class="text-subtitle-2 pb-1">{{ item.subtitle | moment('timezone', 'Asia/Yekaterinburg', 'LLL') }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="text-body-1 px-0">
              <v-list dense>
                <v-list-item
                  v-for="(text, idx) in item.text"
                  :key="idx"
                >
                  <v-list-item-content class="py-1">{{ text }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="620px">
      <v-form
        ref="commentForm"
        v-model="validComment"
        lazy-validation
      >
        <v-card>
          <v-card-title class="headline">Добавить комментарий</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <tiptap-vuetify
              v-model="commentText"
              placeholder="Комментарий"
              :rules="[v => !!v || 'Добавьте комментарий']"
              :extensions="extensions"
            ></tiptap-vuetify>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="green darken-1" text @click="closeDialog">Отмена</v-btn>
            <v-btn color="green darken-1" :disabled="!validComment" text @click="addComment">Добавить</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, /* Paragraph, */ BulletList, OrderedList, ListItem, /* Link, */ Blockquote, HardBreak, /* HorizontalRule, */ History } from 'tiptap-vuetify'
export default {
  name: 'VViewTabs',
  components: { TiptapVuetify },
  props: {
    type: {
      default: null,
      type: String
    },
    id: {
      default: null,
      type: Number
    },
    viewComments: {
      default: false,
      type: Boolean
    },
    viewHistory: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    validComment: true,
    commentText: '',
    actionGetComment: {
      task: 'comments/GET_COMMENTS_TASK',
      project: 'comments/GET_COMMENTS_PROJECT'
    },
    actionPostComment: {
      task: 'comments/POST_COMMENT_TASK',
      project: 'comments/POST_COMMENT_PROJECT',
      plan: 'comments/POST_COMMENT_PLAN'
    },
    actionGetHistory: {
      task: 'comments/GET_HISTORIES_TASK',
      project: 'comments/GET_HISTORIES_PROJECT'
    },
    extensions: [
      History,
      Blockquote,
      // Link,
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [2, 3]
        }
      }],
      Code,
      // HorizontalRule,
      // Paragraph,
      HardBreak
    ]
  }),
  created () {
    this.getData()
    this.$eventHub.$on('refreshVViewTabs', this.getData)
  },
  destroyed () {
    this.$eventHub.$off('refreshVViewTabs', this.getData)
  },
  computed: {
    comments: function () {
      return this.$store.state.comments.comments
    },
    histories: function () {
      return this.$store.state.comments.histories
    },
    dialog: function () {
      return this.$store.state.comments.commentDialog
    },
    getType: function () {
      if (this.type === 'task') {
        return 'задачу'
      } else if (this.type === 'project') {
        return 'проект'
      }
      return 'план'
    }
  },
  methods: {
    getData () {
      if (this.viewComments) {
        this.$store.dispatch('comments/GET_COMMENTS', { id: this.id, type: this.type })
      }
      if (this.viewHistory) {
        this.$store.dispatch('comments/GET_HISTORIES', { id: this.id, type: this.type })
      }
    },
    addComment () {
      if (!this.$refs.commentForm.validate()) {
        return
      }
      this.validComment = false
      const id = this.id
      const payload = {
        comment: this.commentText
      }
      this.$store.dispatch(this.actionPostComment[this.type], { id, payload })
        .then(() => {
          this.getData()
          this.$store.commit('comments/CLOSE_COMMENT_DIALOG')
          this.commentText = ''
          this.flashMessage.success({ title: 'Сохранение', message: 'Комментарий добавлен' })
          this.$emit('onAddComment')
          this.validComment = true
        })
    },
    closeDialog () {
      this.$store.commit('comments/CLOSE_COMMENT_DIALOG')
    }
  },
  watch: {
    id: 'getData'
  }
}
</script>

<style scoped>
.tiptap-vuetify-editor__content p {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}
</style>
