<template>
  <div>
    <v-row>
      <v-col cols="12" lg="6">
        <v-menu
          offset-y
          :close-on-content-click="false"
          :close-on-click="false"
          ref="menuFilter"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              dense
              error-count="0"
              readonly
              label="Фильтр"
              ref="textInputFilter"
            >
              <template v-slot:prepend-inner>
                <v-chip
                  v-for="(item, key) in filtersInner"
                  :key="key"
                  small
                  close
                  @click:close="deleteFilter(item)"
                  class="mr-1"
                >{{ item.text }}{{ getFilterValue(item) }}</v-chip>
              </template>
            </v-text-field>
          </template>
          <v-card>
            <v-card-title class="text-subtitle-1 py-2 flex justify-space-between">
              <span>Фильтр</span>
              <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(item, key) in filterItemsInner"
                  :key="key"
                  cols="12"
                  :lg="calculateCol(item)"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-if="item.type === 'item'"
                    v-model="item.val"
                    :label="item.text"
                    dense
                  />
                  <v-text-field
                    v-if="item.type === 'text'"
                    v-model="item.val"
                    flat
                    background-color="transparent"
                    hide-details
                    dense
                    clearable
                    :label="item.text"
                    @click:clear="clear(item)"
                  ></v-text-field>
                  <v-select
                    v-if="item.type === 'select'"
                    v-model="item.val"
                    :items="item.select"
                    item-text="name"
                    item-value="id"
                    flat
                    background-color="transparent"
                    hide-details
                    dense
                    clearable
                    :multiple="item.multi"
                    :label="item.text"
                    @click:clear="clear(item)"
                  ></v-select>
                  <v-autocomplete
                    v-if="item.type === 'combobox'"
                    v-model="item.val"
                    :items="item.select"
                    item-text="name"
                    item-value="id"
                    flat
                    background-color="transparent"
                    hide-details
                    dense
                    clearable
                    :label="item.text"
                    @click:clear="clear(item)"
                  ></v-autocomplete>
                  <v-autocomplete-multisearch
                    v-if="item.type === 'autocomplete'"
                    v-model="item.val"
                    item-value="name"
                    item-text="name"
                    :item-desc="item.additional"
                    :action="item.action"
                    :label="item.text"
                    clearable
                    @click:clear="clear(item)"
                  ></v-autocomplete-multisearch>
                  <v-combo-multisearch
                    v-if="item.type === 'combo_multisearch'"
                    v-model="item.val"
                    :items="item.select"
                    item-value="id"
                    item-text="full_name"
                    :item-desc="item.additional"
                    :label="item.text"
                    :multiple="item.multi"
                    clearable
                    @click:clear="clear(item)"
                  ></v-combo-multisearch>
                  <date-picker
                    v-if="item.type === 'date' && checkVisible(item)"
                    v-model="item.val" dense clearable :label="item.text" @click:clear="clear(item)"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn small @click="apply" color="success">Применить</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAutocompleteMultisearch from './VAutocompleteMultisearch'
import VComboMultisearch from '@/components/VComboMultisearch'

export const isNotEmpty = val => {
  const isArray = Array.isArray(val)
  return (val && !isArray) || (isArray && val.length)
}

export default {
  name: 'VFilter',
  components: { VComboMultisearch, VAutocompleteMultisearch },
  props: {
    filters: Array,
    filterItems: Array
  },
  data: function () {
    return {
      filtersData: [],
      filtersInner: this.filters
    }
  },
  computed: {
    filterItemsInner: function () {
      const items = []
      const self = this
      this.filterItems.forEach(function (item, idx, array) {
        if (Object.prototype.hasOwnProperty.call(item, 'visible')) {
          if (self.$auth.check(item.visible)) {
            items.push(item)
          }
        } else {
          items.push(item)
        }
      })
      return items
    }
  },
  methods: {
    apply () {
      const self = this
      this.$refs.menuFilter.isActive = false
      this.filterItems.filter(({ val }) => isNotEmpty(val)).forEach(function (item) {
        const current = self.filtersInner.find(e => e.field === item.field)
        if (!current) {
          self.filtersInner.push(item)
        }
      })
      this.resetPage()
    },
    clear (item) {
      this.filtersInner = this.filtersInner.filter(elem => elem.field !== item.field)
      item.val = null
      this.resetPage()
    },
    close () {
      this.$refs.menuFilter.isActive = false
    },
    filterData () {
      this.$emit('filterData')
    },
    resetPage () {
      // this.$emit('setFilter', this.filtersData)filtersInner
      this.$emit('setFilter', this.filtersInner)
    },
    getFilterValue (item) {
      let text = ''
      const elem = []
      if (!item.val || item.val.length < 1) return ''
      switch (item.type) {
        case 'combo_multisearch':
          text += ': '
          if (item.multi) {
            item.val.forEach(function (i, idx, array) {
              elem.push(`${item.select.find(({ id }) => id === i).short_name}`)
            })
          } else {
            elem.push(`${item.select.find(({ id }) => id === item.val).short_name}`)
          }
          text += elem.join(', ')
          break
        case 'select':
          text += ': '
          if (item.multi) {
            item.val.forEach(function (i, idx, array) {
              elem.push(`${item.select.find(({ id }) => id === i).name} `)
            })
          } else {
            elem.push(`${item.select.find(({ id }) => id === item.val).name}`)
          }
          text += elem.join(', ')
          break
        case 'combobox':
          text = `: ${item.select.find(({ id }) => id === item.val).name}`
          break
        case 'date':
          text = `: ${this.$moment(item.val).format('L')}`
          break
        case 'text':
        case 'autocomplete':
          text = `: ${item.val}`
          break
      }
      return text
    },
    deleteFilter (item) {
      this.filtersInner = this.filtersInner.filter((i) => i.field !== item.field)
      const elem = this.filterItemsInner.find(i => i.field === item.field)
      if (elem.type === 'item') {
        elem.val = false
      } else {
        elem.val = null
      }
      this.resetPage()
    },
    checkVisible (item) {
      if (Object.prototype.hasOwnProperty.call(item, 'visible')) {
        return this.$auth.check(item.visible)
      }
      return true
    },
    calculateCol (item) {
      if (item.col) {
        return item.col
      }
      return 12
    }
  },
  watch: {
    filtersInner (val, prev) {
      if (val.length === prev.length) return
      this.filtersInner = val.map(v => {
        if (typeof v === 'string') {
          v = {
            field: 'name',
            text: 'Название',
            val: v
          }
        }
        return v
      })
    }
  }
}
</script>

<style scoped>

</style>
