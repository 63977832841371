<template>
  <v-text-field
    v-bind="$attrs"
    v-on="myListeners"
    type="text"
    v-mask="config"
    :value="display"
    @input.native="onInput"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </v-text-field>
</template>

<script>
import mask from 'vue-the-mask/src/directive'
import tokens from 'vue-the-mask/src/tokens'
import masker from 'vue-the-mask/src/masker'

export default {
  name: 'VMaskedInput',
  props: {
    value: [String, Number],
    mask: {
      type: [String, Array],
      required: true
    },
    masked: { // by default emits the value unformatted, change to true to format with the mask
      type: Boolean,
      default: false // raw
    },
    tokens: {
      type: Object,
      default: () => tokens
    }
  },
  directives: { mask },
  data () {
    return {
      lastValue: null, // avoid unecessary emit when has no change
      display: this.value
    }
  },
  updated () {
    this.refresh(this.display)
  },
  watch: {
    value (newValue) {
      if (newValue !== this.lastValue) {
        this.display = newValue
      }
    },
    masked () {
      this.refresh(this.display)
    }
  },
  computed: {
    config () {
      return {
        mask: this.mask,
        tokens: this.tokens,
        masked: this.masked
      }
    },
    myListeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    }
  },
  methods: {
    onInput (e) {
      if (e.isTrusted) return // ignore native event
      this.refresh(e.target.value)
    },

    refresh (newValue) {
      this.display = newValue
      const value = masker(newValue, this.mask, this.masked, this.tokens)
      if (value !== this.lastValue) {
        this.lastValue = value
        this.$emit('input', value)
      }
    }
  }
}
</script>
