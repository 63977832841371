<template>
  <div>
    <v-row>
      <v-list dense>
        <v-subheader>ФАЙЛЫ</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in attachInner"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-cloud</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title @click="downloadFile(item.filename)">
                {{ item.name }} <span class="grey--text">Версия: {{ item.version }} ({{ $moment(item.updated_dt).format('DD.MM.YYYY hh:mm:ss') }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>
    <v-row v-if="!isClosed">
      <v-col cols="12" lg="6" class="d-flex flex-row">
        <v-file-input
          v-model="files"
          label="Загрузка файлов"
          multiple
          chips
          dense
        />
        <v-btn
          outlined
          small
          color="green darken-1"
          @click="uploadFile"
          :disabled="files.length === 0"
        >Загрузить</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'VAttachment',
  props: {
    isClosed: Boolean,
    type: String,
    id: Number
  },
  data: function () {
    return {
      files: [],
      attachInner: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.$store.dispatch('attachment/GET_ATTACHMENTS', { id: this.id, type: this.type }).then(response => {
        this.$set(this, 'attachInner', response)
      })
    },
    uploadFile () {
      const formData = new FormData()
      this.$store.commit('OVERLAY_ON')
      for (let i = 0; i < this.files.length; i++) {
        formData.append(`file[${i}]`, this.files[i])
      }
      this.$store.dispatch('attachment/POST_UPLOAD_FILES', { id: this.id, type: this.type, formData: formData }).then(response => {
        this.$set(this, 'attachInner', response.attach)
        this.flashMessage.success({ title: 'Загрузка', message: 'Файлы загружены' })
      }).catch(error => {
        console.log(error.response.data.message || '')
        this.flashMessage.error({ title: 'Загрузка', message: 'Ошибка загрузки файлов' })
      }).finally(() => {
        this.files = []
        this.$store.commit('OVERLAY_OFF')
      })
    },
    downloadFile (file) {
      this.$store.dispatch('attachment/GET_DOWNLOAD_FILE', { id: this.id, type: 'task', filename: file })
        .then(resp => {
          const headers = resp.headers
          const blob = new Blob([resp.data], { type: headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = file
          link.click()
        })
    }
  },
  watch: {
    $route: 'getData'
  }
}
</script>

<style scoped>

</style>
