<template>
  <v-row justify="center">
    <v-dialog
      v-model="openPlan"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark>
        <v-toolbar-title>План этажа</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closePlan">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card>
        <v-img
          :src="sourceImage"
          contain
        ></v-img>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'VPlan',
  props: {
    pathPlan: {
      type: String,
      default: null
    }
  },
  computed: {
    openPlan: function () {
      return Boolean(this.pathPlan)
    },
    sourceImage: function () {
      return `https://red-static.website.yandexcloud.net/${this.pathPlan}`
    }
  },
  methods: {
    getPlan: function () {
    },
    closePlan: function () {
      this.$emit('closePlan')
    }
  }
}
</script>

<style scoped>

</style>
