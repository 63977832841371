<template>
  <v-container
    id="data-tables"
    tag="section"
    fluid
  >
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Заявки"
    >
      <v-row>
        <v-col cols="12">
          <v-filter
            :filters="filters"
            :filter-items="filterItems"
            @filterData="filterData"
            @setFilter="resetPage"
          ></v-filter>
        </v-col>
      </v-row>
      <v-divider/>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="footerProps"
        item-key="id"
        @click:row="rowClick"
      >
        <template v-slot:item.priority="{ item }">
          <v-chip :color="getPriority(item.priority).color" dark>
            {{ getPriority(item.priority).name }}
          </v-chip>
        </template>
        <template v-slot:item.workers="{ item }">
          <span
            v-for="(worker, key) in item.workers"
            :key="key"
            class="d-block"
          >{{ worker }}</span>
        </template>
      </v-data-table>
    </base-card>
  </v-container>
</template>

<script>
import pagination from '../../mixins/pagination'
import VFilter from '../../components/VFilter'
export default {
  name: 'TasksList',
  components: { VFilter },
  mixins: [pagination],
  data: () => ({
    priorities: [
      { value: 5, name: 'Авария', color: 'red' },
      { value: 10, name: 'Высокий', color: 'orange' },
      { value: 20, name: 'Обычный', color: 'green' },
      { value: 30, name: 'Низкий', color: 'green' }
    ]
  }),
  created () {
    this.getData()
    this.$eventHub.$on('refresh', this.getData)
    this.$eventHub.$on('add', this.addTicket)
  },
  destroyed () {
    this.$eventHub.$off('refresh', this.getData)
    this.$eventHub.$off('add', this.addTicket)
  },
  computed: {
    headers: function () {
      return [
        { text: '№', value: 'full_number', width: '6%' },
        { text: 'Название задачи', value: 'name' },
        { text: 'Дата регистрации', value: 'created_dt' },
        { text: 'Плановая дата', value: 'deadline_dt' },
        { text: 'Приоритет', value: 'priority' },
        { text: 'Статус', value: 'status_name.name', width: '6%' },
        { text: 'Руководитель', value: 'executor', width: '10%' },
        { text: 'Объект', value: 'facility.name', width: '10%' },
        { text: 'Контрагент', value: 'partner.name', width: '10%' },
        { text: 'Исполнители', value: 'workers', width: '10%' }
      ]
    },
    pagination: {
      get: function () {
        return this.$store.state.ticket.pagination
      },
      set: function (value) {
        this.$store.commit('ticket/SET_PAGINATION', value)
      }
    },
    filterItems: function () {
      return this.$store.state.ticket.filterItems
    },
    filters: {
      get: function () {
        return this.$store.state.ticket.filters
      },
      set: function (value) {
        this.$store.commit('ticket/SET_FILTERS', value)
      }
    }
  },
  methods: {
    async getData () {
      await this.$store.dispatch('ticket/GET_ITEMS')
      this.filterData()
    },
    filterData () {
      this.setParams()
      this.$store.dispatch('ticket/GET_TICKETS', this.getParams).then(response => {
        this.items = response.tickets
        this.meta = response.meta
      })
    },
    getPriority (val) {
      return this.priorities.find(priority => priority.value === val)
    },
    rowClick (event) {
      this.$router.push({ path: `/tickets/${event.id}/view` })
    },
    changeClosed () {
      this.filterData()
    },
    expiredTask (item) {
      if (this.closed) {
        return
      }
      if (this.$moment().valueOf() > this.$moment(item.deadline_dt, 'DD.MM.YYYY').add(1, 'days').valueOf()) {
        return 'red lighten-2'
      } else if (this.$moment().valueOf() > this.$moment(item.deadline_dt, 'DD.MM.YYYY').valueOf()) {
        return 'orange lighten-2'
      }
    },
    copyTask (id) {
      this.$store.commit('task/SET_COPY_TASK_ID', id)
      this.$router.push({ path: '/tasks/create' })
    },
    visibleCopyButton: function (item) {
      return [item.customer_id, item.creator_id].indexOf(this.$auth.user().id) !== -1
    },
    addTicket () {
      this.$router.push({ name: 'TicketCreate' })
    }
  }
}
</script>

<style scoped>

</style>
