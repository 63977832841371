<template>
  <v-card class="mt-3">
    <v-card-title>Подзадачи</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-simple-table height="280px" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">№</th>
              <th class="text-left">Тип</th>
              <th class="text-left">Наименование</th>
              <th class="text-left">Статус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <v-chip
                  label
                  link
                  small
                  @click="$router.push({ path: `/tasks/${item.id}/view`})"
                >
                  {{ item.full_number }}
                </v-chip>
              </td>
              <td>{{ item.subtask_type | mapSubtaskType }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.status_name.name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'VSubTask',
  props: {
    id: Number
  },
  data: () => ({
    items: [],
    headers: [
      { text: '№', value: '' },
      { text: 'Наименование', value: '' },
      { text: 'Статус', value: '' }
    ]
  }),
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$store.dispatch('task/GET_SUBTASKS', this.id).then(response => {
        this.items = response.subtasks
      })
    }
  },
  filters: {
    mapSubtaskType (pure) {
      const map = new Map([
        [0, 'Связанная'],
        [1, 'Блокирующая']
      ])
      return map.get(pure)
    }
  }
}
</script>

<style scoped>

</style>
