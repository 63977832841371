<script>
import { VTextField } from 'vuetify/es5/components/VTextField'
export default {
  name: 'VNumber',
  extends: VTextField,
  props: {
    value: {
      default: 0
    },
    negative: {
      default: false,
      type: Boolean
    },
    type: {
      default: 'number'
    }
  },
  mounted () {
    this.$on('blur', this.fixValue)
  },
  methods: {
    fixValue () {
      let v = this.value
      v = parseFloat(this.value).toFixed(2)
      if ((v < 0 && !this.negative) || isNaN(v)) {
        v = 0
      }
      this.$emit('input', v)
    }
  }
}
</script>

<style scoped>

</style>
