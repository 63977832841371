<template>
  <div>
    <v-form
      ref="task_form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="8">
          <v-card class="px-3 py-2" elevation="1">
            <v-text-field
              v-model="fields.name"
              label="Наименование задачи"
              :rules="rules.required"
              :readonly="enabledFieldIsDraft"
            />
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="fields.priority"
                  :items="items_priority"
                  item-value="value"
                  item-text="name"
                  label="Приоритет"
                  :readonly="!$auth.check(['braude', 'root'])"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="fields.category"
                  :items="items_category"
                  item-value="code"
                  item-text="name"
                  label="Категория"
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                  @change="change_category"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <date-picker
                  v-model="fields.start_dt"
                  label="Начать"
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                  @change="changeIntervalAndUser"
                />
              </v-col>
              <v-col cols="6">
                <date-picker
                  v-model="fields.deadline_dt"
                  label="Срок"
                  :min="fields.start_dt"
                  :max="maxDate()"
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                  @change="changeIntervalAndUser"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-number
                  v-model="fields.labor_costs"
                  label="Трудозатраты (часы)"
                  type="number"
                  :rules="rules.labor_costs"
                  :readonly="enabledFieldIsDraft"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="fields.tags"
                  :items="tags"
                  item-value="id"
                  item-text="name"
                  label="Тэги"
                  multiple
                  :readonly="enabledFieldIsDraft"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip small>{{ item.name }}</v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-combo-multisearch
                  v-model="fields.executor_id"
                  :items="items_users"
                  item-value="id"
                  item-text="full_name"
                  item-desc="position"
                  label="Исполнитель"
                  :table-view="tableTask"
                  avatar
                  clearable
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                  @change="changeIntervalAndUser"
                  @showTable="showTable"
                />
              </v-col>
              <v-col cols="6" v-if="$auth.check('supervisor') && fields.category == 'task_corr'">
                <v-combo-multisearch
                  v-model="fields.customer_id"
                  :items="items_users"
                  item-value="id"
                  item-text="full_name"
                  item-desc="position"
                  label="Заказчик"
                  avatar
                  clearable
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="fields.goal"
                  label="Цель"
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-for="i in result_count"
                  :key="i"
                  v-model="fields.result[i-1].text"
                  :label="`Ожидаемый результат #${i}`"
                  :rules="rules.required"
                  :readonly="enabledFieldIsDraft"
                >
                  <template v-slot:prepend>{{ `${i}.` }}</template>
                  <template v-slot:append-outer>
                    <v-btn icon color="primary" @click="add_result">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="remove_result(i-1)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-for="i in notallowed_count"
                  :key="i"
                  v-model="fields.not_allowed[i-1].text"
                  label="Нельзя допустить"
                >
                  <template v-slot:prepend>{{ `${i}.` }}</template>
                  <template v-slot:append-outer>
                    <v-btn
                      icon
                      color="primary"
                      @click="add_notallowed"
                    ><v-icon>mdi-plus-thick</v-icon></v-btn>
                    <v-btn
                      icon
                      color="error"
                      @click="remove_notallowed(i-1)"
                    ><v-icon>mdi-minus</v-icon></v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="fields.description"
                  label="Примечание"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="px-3 py-2" elevation="1">
            <v-combo-multisearch
              v-model="fields.watchers"
              :items="items_users"
              item-value="id"
              item-text="full_name"
              item-desc="position"
              multiple
              small-chips
              deletable-chips
              label="Наблюдатели"
              avatar
              :readonly="enabledFieldIsDraft"
            />
            <v-select
              v-model="fields.project_id"
              :items="items_project"
              item-value="id"
              item-text="name"
              clearable
              label="Проект"
              @change="changeProject"
              :readonly="enabledFieldIsDraft"
            />
            <v-select
              v-if="visibleTask"
              v-model="fields.project_task_id"
              :items="project_tasks"
              item-value="id"
              item-text="name"
              clearable
              label="Задачи проекта"
              :rules="rules.required"
              :readonly="enabledFieldIsDraft"
            ></v-select>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" class="ma-2" :disabled="!valid" @click="saveData">Сохранить</v-btn>
          <v-btn color="warning" class="ma-2" @click="$router.go(-1)">Отменить</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="tableDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-title>Задачи свыше 14 дней</v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableItems"
            :footer-props="footerProps"
          >
            <template v-slot:item.task="{ item }">
              <a :href="`/tasks/${item.id}/view`" target="_blank">{{ item.task }}</a>
            </template>
            <template v-slot:body.append="{ headers }">
              <tr>
                <th
                  v-for="(header, i) in headers"
                  :key="i"
                  :class="{
                    'text-right v-data-table__divider': ['task', 'customer'].indexOf(header.value) > -1,
                    'text-center v-data-table__divider': ['task', 'customer'].indexOf(header.value) === -1
                  }">
                  {{ calculateTotal(header.value) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined small @click="closeTable">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import VComboMultisearch from '../VComboMultisearch'
import VNumber from '../VNumber'
// import MaskedInput from 'vue-text-mask'
export default {
  name: 'TaskEditForm',
  components: {
    VNumber,
    VComboMultisearch
  },
  props: {
    fields: {
      type: Object,
      default: function () {
        return {
          name: '',
          executor_id: null, // исполнитель
          customer_id: null,
          project_id: null,
          project_task_id: undefined,
          deadline_dt: '',
          start_dt: '',
          status: 'draft',
          priority: null,
          category: 'task',
          goal: '',
          result: [
            { text: '' }
          ],
          not_allowed: [
            { text: '' }
          ],
          description: '',
          watchers: [],
          tags: []
        }
      }
    }
  },
  data: () => ({
    valid: true,
    rules: {
      required: [v => !!v || 'Обязательное поле'],
      labor_costs: [v => !!parseFloat(v) || 'Обязательное поле']
    },
    tableTask: false,
    tableItems: [],
    tableDialog: false,
    tableHeaders: [],
    footerProps: {
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true
    }
  }),
  created () {
    this.getItems()
  },
  computed: {
    headers: function () {
      const h = [
        { value: 'customer', text: 'Заказчик', divider: true, align: 'left' },
        { value: 'task', text: 'Задача', divider: true, align: 'left' }
      ]
      this.tableHeaders.forEach(function (item) {
        h.push({ value: item, text: item, divider: true, align: 'center' })
      })
      return h
    },
    result_count: function () {
      return (this.fields.result === undefined) ? 0 : this.fields.result.length
    },
    items_users: function () {
      return this.$store.state.task.items.users
    },
    items_priority: function () {
      return this.$store.state.task.items.priority
    },
    items_category: function () {
      return this.$store.state.task.items.category
    },
    items_project: function () {
      return this.$store.state.task.items.project
    },
    project_tasks: function () {
      return this.$store.state.task.project_task
    },
    tags: function () {
      return this.$store.state.task.items.tags
    },
    visibleTask: function () {
      return this.fields.project_id !== undefined && this.fields.project_id !== null
    },
    enabledFieldIsDraft: function () {
      return this.fields.status !== 'draft'
    },
    notallowed_count: function () {
      return (this.fields.not_allowed === undefined) ? 0 : this.fields.not_allowed.length
    }
  },
  methods: {
    getItems () {
      // this.$store.dispatch('task/GET_ITEMS')
    },
    saveData () {
      this.$emit('onTaskSave', this.$refs.task_form)
    },
    changeProject () {
      this.$store.dispatch('task/GET_PROJECT_TASKS', this.fields.project_id || 0)
    },
    add_result () {
      this.fields.result.push({
        text: ''
      })
    },
    remove_result (val) {
      if (this.result_count > 1) {
        this.fields.result.splice(val, 1)
      }
    },
    add_notallowed () {
      this.fields.not_allowed.push({
        text: ''
      })
    },
    remove_notallowed (val) {
      if (this.notallowed_count > 1) {
        this.fields.not_allowed.splice(val, 1)
      }
    },
    change_category (value) {
      this.$emit('processId', this.items_category.find(item => item.code === value).workflow_id)
    },
    changeIntervalAndUser () {
      if (this.fields.start_dt && this.fields.deadline_dt && this.fields.executor_id) {
        const payload = {
          start: this.fields.start_dt,
          end: this.fields.deadline_dt,
          user_id: this.fields.executor_id
        }
        this.$store.dispatch('task/POST_CHECK_TASK_COUNT', payload).then(response => {
          if (response) {
            this.flashMessage.error({ title: 'Предупреждение', message: 'Количество задач, свыше 14 дней, достигло максимума в 8 задач или более.' })
            this.tableTask = response.status === 'overflow'
            this.tableHeaders = response.headers
            this.tableItems = response.data
          } else {
            this.tableTask = false
            this.tableItems = []
          }
        })
      } else {
        this.tableTask = false
        this.tableItems = []
      }
    },
    showTable () {
      this.tableDialog = true
    },
    closeTable () {
      this.tableDialog = false
    },
    calculateTotal (key) {
      let sum = 0
      switch (key) {
        case 'customer':
          return 'Итого:'
        case 'task':
          return ''
      }
      this.tableItems.forEach(function (item, idx, array) {
        sum = sum + item[key]
      })
      return sum.toFixed(2)
    },
    filter (item, queryText, itemText) {
      return (
        item.full_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.position.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    maxDate () {
      return this.$moment(this.fields.start_dt, 'YYYY-MM-DD').add(90, 'days').format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
