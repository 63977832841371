<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Карточка заявки"
    >
      <v-row>
        <v-col cols="12" lg="7">
          <v-btn
            v-if="['distributed', 'rework'].indexOf(status_code) > -1 &&
             ($auth.user().id === this.fields.executor_id ||
              $auth.user().repl_id === this.fields.executor_id ||
              $auth.check(['root'])
              )"
            small
            color="primary"
            class="mr-3"
            @click.stop="addWorkersDialog"
          >Добавить исполнителей</v-btn>
          <v-btn
            v-if="$auth.check(['kcadmin'])"
            @click="priorityDialog = true"
            small color="error">Изменить приоритет</v-btn>
        </v-col>
        <v-col cols="6" lg="3">
          <div v-for="(value, name, idx) in fields.appraisals" :key="idx" class="d-flex justify-start">
            <span class="text-body-2 pt-1" style="width: 40%">{{ name|mapRating }}</span>
            <v-rating :value="value" readonly dense></v-rating>
          </div>
        </v-col>
        <v-col cols="6" lg="2">
          <div><span class="subtitle-2">Статус: </span><span class="body-2 text-uppercase">{{ status.status || '' }}</span></div>
          <div><span class="subtitle-2">На ком: </span><span class="body-2">{{ status.whom || '' }}</span></div>
        </v-col>
      </v-row>
<!--      <v-skeleton-loader :loading="loading" type="article" class="mx-auto">-->
        <v-row>
          <v-col cols="12" lg="8">
            <v-skeleton-loader :loading="loading" type="chip, list-item@11" class="mx-auto">
              <v-tabs>
                <v-tab key="ticket">Заявка</v-tab>
                <v-tab key="working">Работы</v-tab>
                <v-tab-item key="ticket">
                  <v-list class="view-list v13">
                    <v-list-item>
                      <v-list-item-content>Заявка №:</v-list-item-content>
                      <v-list-item-content>{{ fields.code_task }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Наименование заявки:</v-list-item-content>
                      <v-list-item-content>{{ fields.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Тип:</v-list-item-content>
                      <v-list-item-content>{{ fields.ticket_type.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Заявитель:</v-list-item-content>
                      <v-list-item-content>{{ fields.ticket_declarer.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Контрагент:</v-list-item-content>
                      <v-list-item-content>
                        <v-card flat class="d-flex justify-start align-center">
                          <span>{{ fields.partner.name }}</span>
                          <v-icon class="ml-2" color="accent" @click.stop="$store.commit('rn/VIEW_PARTNER', fields.partner_id)">mdi-eye</v-icon>
                        </v-card>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Контактное лицо:</v-list-item-content>
                      <v-list-item-content>
                        <v-card flat class="d-flex justify-start align-center">
                          <span>{{ fields.customer.full_name }} ({{ fields.customer.mobile_str | VMask('+7 (###) ###-####') }})</span>
                          <v-icon class="ml-2" color="accent" @click.stop="$store.commit('rn/VIEW_MEMBER', fields.customer_id)">mdi-eye</v-icon>
                        </v-card>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Объект:</v-list-item-content>
                      <v-list-item-content>{{ fields.facility.address }}{{ flat }} ({{ fields.facility.name }})</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Группа:</v-list-item-content>
                      <v-list-item-content>{{ fields.subgroup.group.name }}: {{ fields.subgroup.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Плановая дата выполнения:</v-list-item-content>
                      <v-list-item-content>{{ $moment(fields.deadline_dt).format("L") }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Приоритет:</v-list-item-content>
                      <v-list-item-content>{{ fields.priority_text }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Описание:</v-list-item-content>
                      <v-list-item-content>{{ fields.description }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-tab-item>

                <v-tab-item key="working">
                  <v-expansion-panels focusable>
                    <v-expansion-panel v-for="(item, key) in working" :key="key">
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-col cols="3">Работы от {{ item.created_dt | moment('timezone', 'Asia/Yekaterinburg', 'L LT') }}</v-col>
                            <v-col cols="6" class="text--secondary">
                              <v-fade-transition leave-absolute>
                                <span v-if="open" key="0"></span>
                                <span v-else key="0">Ответственный: {{ getWorkerName(findResponsible(item)) }}</span>
                              </v-fade-transition>
                            </v-col>
                            <v-col cols="3" class="text--secondary"><span v-if="item.is_closed">Работы закрыты</span></v-col>
                          </v-row>
                        </template>
                        <template v-slot:actions v-if="item.is_closed">
                          <v-icon color="success">mdi-check-circle</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" lg="5">
                            <v-list class="view-list v31" subheader>
                              <v-subheader>Исполнители</v-subheader>
                              <v-list-item v-for="(worker, i) in item.executor" :key="i" three-line>
                                <v-list-item-content>
                                  <v-list-item-title :class="{'text-decoration-line-through': !!worker.end_dt}">
                                    {{ getWorkerName(worker) }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="worker.responsible">
                                    Ответственный исполнитель
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    Назначен: {{ worker.start_dt | moment('timezone', 'Asia/Yekaterinburg', 'L LT') }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <div v-if="!item.is_closed && !worker.end_dt && ['distributed', 'rework'].indexOf(status_code) > -1" class="d-flex justify-start">
                                    <v-btn
                                      icon color="red darken-1" class="ml-2" v-tooltip.bottom="`Смена исполнителя`"
                                      @click="changWorkerDialog(worker.user_id, worker.guid)"
                                    >
                                      <v-icon>mdi-account-switch</v-icon>
                                    </v-btn>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col cols="12" lg="7" class="mt-3">
                            <div class="d-flex justify-space-between">
                              <span>{{ $moment.duration(item.time * 1000).format('h [час.] m [мин.]') }}</span>
                              <v-btn small outlined @click="addTime(item.guid)">Добавить время</v-btn>
                            </div>
                            <v-divider class="my-6"></v-divider>
                            <div class="d-flex justify-end">
                              <v-btn small outlined @click="addWork(item.guid)">Добавить</v-btn>
                            </div>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                <tr>
                                  <th class="text-left">Работы</th>
                                  <th class="text-left">Цена, руб. без НДС</th>
                                  <th class="text-left">Цена вх., руб. без НДС</th>
                                  <th class="text-left"></th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(work, w) in item.work_list" :key="w">
                                    <td>{{ (work.price_name) ? work.price_name.name : "" }}</td>
                                    <td>{{ work.price }}</td>
                                    <td>{{ work.price_in }}</td>
                                    <td>
                                      <v-btn icon @click="editWork(work, item.guid)"><v-icon>mdi-pencil</v-icon></v-btn>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-divider class="my-6"></v-divider>
                            <div class="d-flex justify-end">
                              <v-btn small outlined @click="addMaterial(item.guid)">Добавить</v-btn>
                            </div>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                <tr>
                                  <th class="text-left">Материаты</th>
                                  <th class="text-left">Цена, руб. без НДС</th>
                                  <th class="text-left">Цена вх., руб. без НДС</th>
                                  <th class="text-left"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(material, m) in item.materials" :key="m">
                                  <td>{{ material.name }}</td>
                                  <td>{{ material.price }}</td>
                                  <td>{{ material.price_in }}</td>
                                  <td class="d-flex justify-end">
                                    <v-btn icon @click="editMaterial(material, item.guid)"><v-icon>mdi-pencil</v-icon></v-btn>
                                  </td>
                                </tr>
                                </tbody>
                              </template>
                            </v-simple-table>

                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>

              </v-tabs>
            </v-skeleton-loader>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card elevation="1" class="mb-2">
              <v-skeleton-loader :loading="loading" type="card-heading, divider, list-item" class="mx-auto">
                <v-card-title>Руководитель</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="px-2 d-flex align-center">
                    {{ fields.executor.full_name }}
                    <v-btn
                      v-if="$auth.user().id === fields.executor_id || $auth.user().repl_id === fields.executor_id || $auth.check(['admin'])"
                      icon color="red darken-1" class="ml-2" v-tooltip.bottom="`Смена исполнителя`"
                      @click="dialogChangeExecutor">
                      <v-icon>mdi-account-switch</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-skeleton-loader>
            </v-card>

            <v-card elevation="1">
              <v-skeleton-loader :loading="loading" type="card-heading, divider, list-item-three-line, divider, actions" class="mx-auto">
                <v-card-title>Наблюдатели</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="pa-2">
                    <v-chip
                      v-for="(row, idx) in fields.watchers"
                      :key="idx"
                      class="mr-1 mb-2"
                      small
                      :close="$auth.user().id === row.id"
                      @click:close="watcherRemove(row.id)"
                    >
                      <v-avatar left>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ row.short_name }}
                    </v-chip>
                  </v-row>
                  <v-row class="pa-2">
                    <v-combo-multisearch
                      v-model="watchers"
                      :items="items_users"
                      item-value="id"
                      item-text="full_name"
                      item-desc="position"
                      multiple
                      small-chips
                      deletable-chips
                      label="Наблюдатели"
                    />
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-end">
                  <v-btn color="primary" class="ma-2" small @click="watcherAdd">Добавить</v-btn>
                </v-card-actions>
              </v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-attachment :is-closed="fields.is_closed" :id="id" type="task" />
        <v-divider class="mt-5 mb-3"></v-divider>
<!--      </v-skeleton-loader>-->
        <v-row>
          <v-btn color="primary" class="ma-2" small @click="openCommentDialog"><v-icon left>mdi-comment-text-outline</v-icon>Комментарий</v-btn>
          <v-spacer></v-spacer>
          <workflow-button
            :type="`task`"
            :object_id="id"
            :date_obj="{ start_dt: fields.start_dt, deadline_dt: fields.deadline_dt }"
            @changeFields="changeFields"
          />
        </v-row>
    </base-card>
    <v-view-tabs
      :type="`task`"
      :id="id"
      @onAddComment="addComment"
      view-comments
      view-history
    />
    <change-executor-form
      :executor-id="fields.executor_id"
      :executor="executorList"
      :id="id"
      @changeExecutor="changeExecutor"
    ></change-executor-form>
    <v-dialog v-model="workerAddDialog" max-width="560px" persistent>
      <v-card>
        <v-card-title>Исполнители</v-card-title>
        <v-card-text>
          <v-row>
            <v-combo-multisearch
              v-model="workersAdd.responsible"
              :items="workerList"
              item-text="full_name"
              item-value="id"
              item-desc="position"
              label="Ответственный исполнитель"
              outlined
              dense
              clearable
              @change="changeWorkersResponsible"
            />
          </v-row>
          <v-row v-for="(item, key) in workersAdd.worker" :key="key">
            <v-combo-multisearch
              v-model="item.id"
              :items="workerList"
              item-text="full_name"
              item-value="id"
              item-desc="position"
              label="Исполнитель"
              outlined
              clearable
              dense
              @change="changeWorkersWorker(key)"
            />
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="closeAddWorkerDialog">Отмена</v-btn>
          <v-btn class="success" small @click.stop="addWorkers">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="workerDialog" persistent max-width="560px">
      <v-card>
        <v-card-title>Изменение исполнителя</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-combo-multisearch
                v-model="worker.workerId"
                :items="workerList"
                item-text="full_name"
                item-value="id"
                item-desc="position"
                label="Исполнитель"
                outlined
                dense
              ></v-combo-multisearch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="closeWorkerDialog">Отмена</v-btn>
          <v-btn class="success" small @click.stop="saveWorkerDialog">Сменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="workDialog" persistent max-width="560px">
      <v-card>
        <v-card-title>Работы</v-card-title>
        <v-divider class="mb-3"></v-divider>
        <v-card-text>
          <v-form
            ref="workForm"
            v-model="validateFormWork"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="workEdit.price_guid"
                  :items="priceWork"
                  item-text="name"
                  item-value="guid"
                  :rules="rules.required"
                  label="Работы"
                  outlined
                  dense
                ></v-combobox>
                <v-text-field
                  v-model="workEdit.price"
                  type="number"
                  label="Цена, руб. без НДС"
                  outlined
                  dense
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="workEdit.price_in"
                  type="number"
                  label="Цена входная, руб. без НДС"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="closeWorkDialog">Отмена</v-btn>
          <v-btn class="success" small @click.stop="saveWorkDialog" :disabled="!validateFormWork">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="materialDialog" persistent max-width="560px">
      <v-card>
        <v-card-title>Материалы</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="materialForm"
            v-model="validateMaterialDialog"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="materialEdit.name"
                  label="Материал"
                  outlined
                  dense
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="materialEdit.price"
                  type="number"
                  label="Цена, руб. без НДС"
                  outlined
                  dense
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="materialEdit.price_in"
                  type="number"
                  label="Цена входная, руб. без НДС"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="closeMaterialDialog">Отмена</v-btn>
          <v-btn class="success" small @click.stop="saveMaterialDialog" :disabled="!validateMaterialDialog">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="timeDialog" persistent max-width="560px">
      <v-card>
        <v-card-title>Затраченное время (время складывается)</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="timeEdit.hour" label="Часы" outlined dense type="number"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="timeEdit.minutes" label="Минуты" outlined dense type="number"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="closeTimeDialog">Отмена</v-btn>
          <v-btn class="success" small @click.stop="saveTimeDialog">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="priorityDialog" persistent max-width="620px">
      <v-card>
        <v-card-title>Изменение приоритета заявки</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="priorityEdit.priority"
                :items="priorityList"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="priorityEdit.comment"
                placeholder="Комментарий"
                :rules="[v => !!v || 'Добавьте комментарий']"
                :extensions="extensions"
              ></tiptap-vuetify>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="priorityDialogCancel">Отмена</v-btn>
          <v-btn class="success" small @click.stop="priorityDialogSave" :disabled="disableSaveChangePriority">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import VComboMultisearch from '../../components/VComboMultisearch'
import VViewTabs from '../../components/VViewTabs'
import WorkflowButton from '../../components/workflow/WorkflowButton'
import ChangeExecutorForm from '../../components/form/ChangeExecutorForm'
import VAttachment from '../../components/VAttachment'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, /* Link, */ Blockquote, HardBreak, /* HorizontalRule, */ History } from 'tiptap-vuetify'
export default {
  name: 'TicketView',
  components: {
    VAttachment,
    ChangeExecutorForm,
    WorkflowButton,
    VViewTabs,
    VComboMultisearch,
    TiptapVuetify
  },
  data: () => ({
    id: 0,
    loading: false,
    workflow: {
      id: null,
      data_status: [],
      next_data: []
    },
    files: [],
    watchers: [],
    fields: {
      declarer_id: undefined,
      channel_id: undefined,
      partner_id: undefined,
      partner: {},
      customer_id: undefined,
      subgroup_id: undefined,
      priority: 30,
      type_id: undefined,
      name: '',
      deadline_dt: '',
      description: '',
      executor_id: undefined,
      facility_id: undefined,
      flat_id: undefined,
      code_task: '',
      priority_text: '',
      ticket_type: '',
      ticket_declarer: '',
      customer: {},
      facility: {},
      subgroup: { group: {} },
      executor: {},
      attachments: []
    },
    flatType: {
      flat: 'квартира',
      office: 'офис',
      not_residential: 'нежилое',
      parking_place: 'машиноместо',
      mop: 'МОП'
    },
    items: {},
    validateFormWork: true,
    validateMaterialDialog: true,
    workerAddDialog: false,
    workerDialog: false,
    worker: {
      workerId: undefined,
      guid: undefined
    },
    working: [],
    workersAdd: {
      responsible: undefined,
      worker: []
    },
    executorList: [],
    workerList: [],
    workEdit: {
      work_guid: null,
      guid: null,
      price_guid: undefined,
      price: 0,
      price_in: 0
    },
    materialEdit: {
      work_guid: null,
      guid: null,
      name: null,
      price: 0,
      price_in: 0
    },
    timeEdit: {
      guid: null,
      hour: 0,
      minutes: 0
    },
    priorityEdit: {
      priority: null,
      comment: null
    },
    priority: [],
    workDialog: false,
    materialDialog: false,
    timeDialog: false,
    priorityDialog: false,
    priceWork: [],
    extensions: [
      History,
      Blockquote,
      // Link,
      Bold,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [2, 3]
        }
      }],
      Code,
      // HorizontalRule,
      Paragraph,
      HardBreak
    ],
    rules: {
      required: [v => !!v || 'Обязательное поле']
    }
  }),
  created () {
    this.getItem()
    this.$eventHub.$on('back', this.goBack)
    this.$eventHub.$on('refresh', this.getItem)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
    this.$eventHub.$off('refresh', this.getItem)
  },
  computed: {
    status: function () {
      return this.$store.state.bpm.status
    },
    status_code: function () {
      return this.$store.state.bpm.workflowState.obj.status
    },
    items_users: function () {
      return this.$store.state.ticket.items.users
    },
    flat: function () {
      return (this.fields.flat) ? `, ${this.flatType[this.fields.flat.type]} ${this.fields.flat.name}` : ''
    },
    priorityList: function () {
      const self = this
      const list = []
      this.priority.forEach(function (item, idx, array) {
        list.push({
          text: item.name,
          value: item.idx,
          disabled: item.idx >= self.fields.priority
        })
      })
      return list
    },
    disableSaveChangePriority: function () {
      return !(this.priorityEdit.comment && this.priorityEdit.priority && this.priorityEdit.comment !== '<p></p>')
    }
  },
  methods: {
    getItem () {
      this.id = parseInt(this.$route.params.id)
      this.loading = true
      this.$store.dispatch('ticket/GET_TICKET_VIEW', this.id).then(response => {
        this.fields = response.fields
        this.workerList = response.worker
        this.executorList = response.executor
        this.working = this.fields.ticket_work
        delete this.fields.ticket_work
        this.priceWork = response.price_work
        this.priority = response.priority
      }).finally(() => {
        this.loading = false
      })
    },
    openCommentDialog () {
      this.commentText = ''
      this.$store.commit('comments/OPEN_COMMENT_DIALOG')
    },
    addComment () {
      if (this.$store.state.bpm.workflowComment) {
        const action = this.$store.state.bpm.workflowAction
        this.$store.commit('bpm/SET_COMMENT_ALREADY_ADDED', action)
        this.$store.commit('bpm/RESET_COMMENT_STATE')
        this.$store.dispatch('bpm/processWorkflow', action)
      }
    },
    watcherAdd () {
      this.$store.dispatch('task/POST_WATCHER_ADD', { id: this.id, payload: this.watchers })
        .then(resp => {
          this.$set(this, 'watchers', [])
          this.$set(this.fields, 'watchers', resp.watchers)
        })
    },
    watcherRemove (value) {
      this.$store.dispatch('task/POST_WATCHER_REMOVE', { id: this.id, payload: { id: value } })
        .then(resp => {
          this.$set(this.fields, 'watchers', resp.watchers)
        })
    },
    changeFields (value) {
      const _ = this
      value.forEach(function (item, i, arr) {
        _.fields[item.field] = item.val
      })
    },
    dialogChangeExecutor () {
      this.$store.dispatch('task/DIALOG_CHANGE_EXECUTOR', this.executorList)
    },
    changeExecutor (executorId) {
      const payload = {
        executor_id: executorId
      }
      this.$store.dispatch('ticket/POST_CHANGE_EXECUTOR', { id: this.id, payload: payload }).then(response => {
        this.fields.executor = response
        this.$eventHub.$emit('refreshVViewTabs')
      }).finally(() => {
        this.$store.commit('task/DIALOG_CHANGE_EXECUTOR_CLOSE')
      })
    },
    changWorkerDialog (workerId, guid) {
      this.worker.workerId = workerId
      this.worker.guid = guid
      this.workerDialog = true
    },
    saveWorkerDialog () {
      const payload = {
        worker_id: this.worker.workerId,
        guid: this.worker.guid
      }
      this.$store.dispatch('ticket/POST_CHANGE_WORKER', { id: this.id, payload: payload }).then(response => {
        this.working = response
      }).finally(() => {
        this.worker.workerId = undefined
        this.worker.guid = undefined
        this.workerDialog = false
      })
    },
    closeWorkerDialog () {
      this.worker.workerId = undefined
      this.worker.guid = undefined
      this.workerDialog = false
    },
    addWorkersDialog () {
      this.workerAddDialog = true
    },
    changeWorkersResponsible () {
      if (this.workersAdd.responsible) {
        this.workersAdd.worker.push({ id: undefined })
      } else {
        this.workersAdd.worker = []
      }
    },
    changeWorkersWorker (key) {
      if (this.workersAdd.worker[key].id) {
        this.workersAdd.worker.push({ id: undefined })
      } else {
        this.workersAdd.worker.pop()
      }
    },
    addWorkers () {
      this.$store.dispatch('ticket/POST_WORKER', { id: this.id, payload: this.workersAdd }).then(response => {
        this.working = response
        this.closeAddWorkerDialog()
      })
    },
    closeAddWorkerDialog () {
      this.workerAddDialog = false
      this.workersAdd.responsible = undefined
      this.workersAdd.worker = []
    },
    findResponsible (item) {
      return item.executor.find(elem => elem.responsible)
    },

    addWork (guid) {
      this.workEdit.work_guid = guid
      this.workDialog = true
    },
    editWork (item, guid) {
      this.workEdit = {
        work_guid: guid,
        price_guid: (item.price_name) ? item.price_name.guid : undefined,
        price: item.price,
        price_in: item.price_in,
        guid: item.guid
      }
      this.workDialog = true
    },
    closeWorkDialog () {
      this.$refs.workForm.resetValidation()
      this.workEdit = { work_guid: null, price_guid: undefined, price: 0, price_in: 0, guid: null }
      this.workDialog = false
    },
    saveWorkDialog () {
      if (!this.$refs.workForm.validate()) return
      const payload = { ...this.workEdit, price_guid: this.workEdit.price_guid.guid }
      this.$store.dispatch('ticket/POST_WORK', payload).then(response => {
        const w = this.working.find(elem => elem.guid === response.guid)
        w.work_list = response.work_list
      }).catch(error => {
        const message = error.response.data.error || ''
        this.flashMessage.error({ title: 'Сохранение', message: message })
      })
      this.closeWorkDialog()
    },

    addMaterial (guid) {
      this.materialEdit.work_guid = guid
      this.materialDialog = true
    },
    editMaterial (item, guid) {
      this.materialEdit = { work_guid: guid, name: item.name, price: item.price, price_in: item.price_in, guid: item.guid }
      this.materialDialog = true
    },
    closeMaterialDialog () {
      this.materialEdit = { work_guid: null, name: null, price: 0, price_in: 0, guid: null }
      this.materialDialog = false
    },
    saveMaterialDialog () {
      if (!this.$refs.materialForm.validate()) return
      this.$store.dispatch('ticket/POST_MATERIAL', this.materialEdit).then(response => {
        const w = this.working.find(elem => elem.guid === response.guid)
        w.materials = response.materials
      })
      this.closeMaterialDialog()
    },

    addTime (guid) {
      this.timeEdit.guid = guid
      this.timeDialog = true
    },
    closeTimeDialog () {
      this.timeEdit = { guid: null, hour: 0, minutes: 0 }
      this.timeDialog = false
    },
    saveTimeDialog () {
      this.$store.dispatch('ticket/POST_TIME', this.timeEdit).then(response => {
        const w = this.working.find(elem => elem.guid === response.guid)
        w.time = response.time
        this.flashMessage.success({ title: 'Сохранение', message: 'Время добавлено.' })
        this.$eventHub.$emit('refreshVViewTabs')
      }).catch(error => {
        this.flashMessage.error({ title: 'Ошибка', message: error.response.data.error })
      })
      this.closeTimeDialog()
    },
    getWorkerName (item) {
      if (item.is_partner) {
        return item.partners.name
      } else {
        return item.user.full_name
      }
    },
    priorityDialogSave () {
      const payload = { ...this.priorityEdit, id: this.id }
      this.$store.dispatch('ticket/POST_CHANGE_PRIORITY', payload).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Приоритет заявки повышен.' })
        this.priorityDialogCancel()
        this.getItem()
        this.$eventHub.$emit('refreshVViewTabs')
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.message
        this.flashMessage.error({ title, message })
      })
    },
    priorityDialogCancel () {
      this.priorityEdit = {
        priority: null,
        comment: null
      }
      this.priorityDialog = false
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  watch: {
    $route: 'getItem'
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'средняя'],
        ['quality', 'качества'],
        ['term', 'срока'],
        ['budget', 'бюджета'],
        ['main', 'общая']
      ])
      return `Оценка ${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>
  .v-chip--disabled {
    opacity: 0 !important;
  }
</style>
