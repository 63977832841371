<template>
  <v-dialog v-model="dialog" persistent max-width="520px">
    <v-form
      ref="appraisal_form"
    >
      <v-card>
        <v-card-title class="headline">Выставление оценок</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-for="(value, name, idx) in appraisal[index]" :key="idx" class="d-flex justify-start">
            <span class="text-body-1 pt-1" style="width: 30%">{{ name|mapRating }}</span>
            <v-rating :value="value" @input="val => setAppraisal({ name, val })" dense></v-rating>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeDialog">Отмена</v-btn>
          <v-btn color="green darken-1" text @click="goProcess" :disabled="validAppraisal">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'Appraisal',
  props: {
    // index: { // индекс действия (кнопки)
    //   default: null,
    //   type: Number
    // }
  },
  data: () => ({
    date: ''
  }),
  computed: {
    dialog: function () {
      return this.$store.state.bpm.workflowState.appraisalDialog
    },
    appraisal: function () {
      return this.$store.state.bpm.workflowState.appraisal
    },
    index: function () {
      return this.$store.state.bpm.workflowState.appraisalIndex
    },
    validAppraisal: function () {
      let res = false
      const index = this.$store.state.bpm.workflowState.appraisalIndex
      if (index === -1) return true
      const arr = Object.entries(this.$store.state.bpm.workflowState.appraisal[index])
      arr.forEach(function (item, i, array) {
        if (item[1] === 0) {
          res = true
          return res
        }
      })
      return res
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    setAppraisal (payload) {
      payload.index = this.index
      this.$store.commit('bpm/SET_APPRAISAL', payload)
    },
    goProcess () {
      const index = this.index
      this.$store.commit('bpm/SET_APPRAISAL_ACTION', true)
      this.$store.commit('bpm/CLOSE_APPRAISAL_DIALOG')
      this.$store.dispatch('bpm/processWorkflow', index)
    },
    closeDialog () {
      this.$store.commit('bpm/CLOSE_APPRAISAL_DIALOG')
      this.$store.commit('bpm/RESET_APPRAISAL')
    }
  },
  filters: {
    mapRating (pure) {
      const map = new Map([
        ['mean', 'средняя'],
        ['quality', 'качества'],
        ['term', 'срока'],
        ['budget', 'бюджета'],
        ['main', 'общая']
      ])
      return `Оценка ${map.get(pure)}:`
    }
  }
}
</script>

<style scoped>

</style>
